import axios from './config';

const tree = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/category/tree`,
    { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/categories`,
    { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/category/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.categories.get() received error from api request:', err),
  );
};

const getByName = (name, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/category/by-name/${name}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.categories.getByName() received error from api request:', err),
  );
};

const post = (params, successCb, errorCb) => {
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/category`,
    params.category,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const put = (params, successCb, errorCb) => {
  let { category } = params;

  axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/category/${params.id}`,
    category,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const endpoints = {
  tree,
  list,
  get,
  getByName,
  post,
  put,
};

export default endpoints;
