import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import moment from 'moment';
import Input from '../input';
import Loader from '../loader';
import './mail-campaign-form.css';
import EmailContentEditor from '../email-content-editor';
import Select from '../select';
import SendyListsInput from '../sendy-lists-input';
import Textarea from '../textarea';

const defaultMailCampaign = {
  title: '',
  sendDate: '',
  status: 'draft',
  listIds: [],
  content: '',
  structuredContent: '',
};

const defaultErrors = {
  title: [],
  sendDate: [],
  status: [],
  listIds: [],
  content: [],
  structuredContent: [],
};

const MailCampaignForm = props => {
  const [mailCampaign, setMailCampaign] = useState(defaultMailCampaign);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const [displayTestmailsZone, setDisplayTestmailsZone] = useState(false);
  const [testmails, setTestmails] = useState('');
  const [testmailsErrors, setTestmailsErrors] = useState([]);
  const [displayTestmailsSuccess, setDisplayTestmailsSuccess] = useState(false);

  const handleChange = e => {
    setMailCampaign({
      ...mailCampaign,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleTestmailsChange = e => {
    setTestmails(e.target.value);
    setErrors({
      ...errors,
      [testmails]: [],
    });
  };

  const handleMultiChanges = e => {
    const newMailCampaign = { ...mailCampaign };
    const newErrors = { ...errors };

    for (const target of e.targets) {
      newMailCampaign[target.name] = target.value;
      newErrors[target.name] = [];
    }

    setMailCampaign(newMailCampaign);
    setErrors(newErrors);
  };

  const handleSubmit = e => {
    setDisplayTestmailsSuccess(false);
    setErrors(defaultErrors);
    setTestmailsErrors([]);

    const mailCampaignData = { ...mailCampaign };
    mailCampaignData.sendDate = mailCampaignData.sendDate ? moment(mailCampaignData.sendDate, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DD HH:mm:ss') : '';

    const apiMethod = props.id ? api.mailCampaigns.put : api.mailCampaigns.post;
    const apiParams = props.id ? { id: props.id, mailCampaign: mailCampaignData } : { mailCampaign: mailCampaignData };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/campagnes');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  const handleTestmailsSubmit = e => {
    setDisplayTestmailsSuccess(false);
    setErrors(defaultErrors);
    setTestmailsErrors([]);

    const mailCampaignData = { ...mailCampaign };
    mailCampaignData.sendDate = mailCampaignData.sendDate ? moment(mailCampaignData.sendDate, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DD HH:mm:ss') : '';

    const apiMethod = api.mailCampaigns.postTest;
    const apiParams = { mailCampaign: mailCampaignData, emails: testmails };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setDisplayTestmailsSuccess(true);
        setIsLoading(false);
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          const teErrors = [];
          err.response.data.violations.map(error => {
            if (error.propertyPath === 'emails') {
              return teErrors.push(error.title);
            }
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
          setTestmailsErrors(teErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.mailCampaigns.get(
        props.id,
        mailcampaign => {
          let newMailCampaign = {
            ...defaultMailCampaign,
            ...mailcampaign,
            sendDate: mailcampaign.sendDate ? moment(mailcampaign.sendDate).format('DD/MM/YYYY HH:mm') : '',
          };

          setMailCampaign(newMailCampaign);
          setIsLoading(false);
        },
      );
    } else if (props.fromId) {
      setIsLoading(true);
      api.mailCampaigns.get(
        props.fromId,
        mailcampaign => {
          let newMailCampaign = {
            ...defaultMailCampaign,
            listIds: mailcampaign.listIds,
            content: mailcampaign.content,
            structuredContent: mailcampaign.structuredContent,
          };

          setMailCampaign(newMailCampaign);
          setIsLoading(false);
        },
      );
    } else {
      setMailCampaign(defaultMailCampaign);
    }
  }, [props.id, props.fromId]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="mail-campaign-form">
      <Button
        className="mail-campaign-form_test-zone-button"
        label="Tester la campagne"
        type="button"
        size="sm"
        theme="secondary"
        onClick={() => setDisplayTestmailsZone(!displayTestmailsZone)}
      />

      {displayTestmailsSuccess && (
        <div className="mail-campaign-form_test-mail-success-message">
          <p>Les emails ont bien été envoyés</p>
        </div>
      )}

      {displayTestmailsZone && (
        <div className="mail-campaign-form_test-mail">
          <Textarea
            errors={testmailsErrors}
            label="Tester la campagne : liste d'emails"
            name="testmails"
            onChange={handleTestmailsChange}
            style={{ width: '100%', marginBottom: '15px' }}
            value={testmails}
            tips="Un mail par ligne, ou séparés par des virgules"
            rows="20"
          />

          <Button
            className="mail-campaign-form_test-mail_submit-button"
            label="Lancer le test"
            theme="info"
            type="button"
            onClick={handleTestmailsSubmit}
          />
        </div>
      )}

      <Form
        className="mail-campaign-form_form"
        onSubmit={(e) => { e.preventDefault(); return false; }}
      >
        <div className="mail-campaign-form_column">
          <Input
            errors={errors.title}
            label="Titre"
            name="title"
            onChange={handleChange}
            type="text"
            value={mailCampaign.title}
          />

          <Input
            errors={errors.sendDate}
            label="Date d'envoi"
            name="sendDate"
            onChange={handleChange}
            style={{ width: '100%', marginRight: '10px' }}
            value={mailCampaign.sendDate}
            tips="JJ/MM/AAAA HH:mm"
            type="text"
          />

          <Select
            errors={errors.status}
            label="Statut"
            name="status"
            options={[
              {
                label: 'En préparation',
                value: 'draft',
              },
              {
                label: 'Prêt',
                value: 'prepared',
              },
              {
                label: 'Envoyé',
                value: 'sent',
              },
              {
                label: 'Erreur',
                value: 'error',
              },
            ]}
            onChange={handleChange}
            value={mailCampaign.status}
          />

          <SendyListsInput
            errors={errors.listIds}
            name="listIds"
            onChange={handleChange}
            style={{ width: '100%', marginRight: '10px' }}
            value={mailCampaign.listIds}
          />
        </div>

        <div className="mail-campaign-form_content">
          <EmailContentEditor
            errors={errors.content}
            label="Contenu"
            htmlFieldName="content"
            structuredFieldName="structuredContent"
            onChange={handleMultiChanges}
            htmlFieldValue={mailCampaign.content}
            structuredFieldValue={mailCampaign.structuredContent}
          />
        </div>

        <Button
          className="mail-campaign-form_submit-button"
          label={`${props.id ? 'Modifier' : 'Créer'} la campagne`}
          theme="success"
          type="button"
          onClick={handleSubmit}
        />
      </Form>
    </div>
  );
};

MailCampaignForm.propTypes = {
  id: PropTypes.number,
  fromId: PropTypes.number,
};

export default MailCampaignForm;
