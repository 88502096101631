import React, { useState } from 'react';
import { useAuth } from '../../context/auth-context';
import Button from '../button';
import Input from '../input';
import './login-form.css';

const LoginForm = () => {
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    username: '',
    password: '',
  });
  const handleChange = e => {
    const { name, value } = e.target;

    setForm(form => ({ ...form, [name]: value }));
    setError(false);
  };

  return (
    <form
      className="login-form"
      onSubmit={e => {
        e.preventDefault();
        login(form, () => setError(true));
      }}
    >
      {error && (
        <p className="login-form_error">
          <b>Erreur :</b> identifiants incorrects.
        </p>
      )}
      <Input
        autoComplete="off"
        autoFocus={true}
        label="Identifiant"
        name="username"
        onChange={handleChange}
        spellCheck={false}
        style={{ marginTop: '0' }}
        type="text"
        value={form.login}
      />
      <Input
        label="Mot de passe"
        name="password"
        onChange={handleChange}
        type="password"
        value={form.password}
      />
      <Button
        label="Connexion"
        style={{ marginTop: '32px', width: '100%' }}
        theme="theme"
        type="submit"
      />
      {/* <div className="login-form_line login-form_line--last">
        <button
          className="login-form_button"
          type="submit"
        >
          Se connecter
        </button>
      </div> */}
    </form>
  );
};

export default LoginForm;
