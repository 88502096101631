import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Paginator from '../paginator';
import api from '../../api';
import './user-list.css';
import { rolesToLabel } from '../../lib/roles';
import Select from '../select';

let searchUpdateTimeout = null;

const UserRoleDisplay = ({ roleLabel }) => (
  <span className={`user-list_role-badge user-list_role-badge--${roleLabel.toLowerCase()}`}>
    {roleLabel}
  </span>
);

const ActiveToggle = ({ user }) => {
  const [state, setState] = useState(user.active);
  const [isLoading, setIsLoading] = useState(false);

  const generateClassname = () => {
    let className = 'user-list_active-toggle';

    if (state) {
      className += ' user-list_active-toggle--active';
    }

    if (isLoading) {
      className += ' user-list_active-toggle--loading';
    }

    return className;
  };

  const toggle = () => {
    setIsLoading(true);
    api.users.put(
      {
        id: user.id,
        user: {
          active: !state,
        },
      },
      () => {
        setState(!state);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <span
      className={generateClassname()}
      onClick={e => {
        e.preventDefault();

        if (!isLoading) {
          toggle();
        }
      }}
    >
      <i className="user-list_active-toggle-icon material-icons">{state ? 'toggle_on' : 'toggle_off'}</i>
    </span>
  );
};

const UserList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const role = query.get('role') ? query.get('role') : '';
  const search = query.get('search') ? query.get('search') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'redactionDate';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.role || (role && !params.hasOwnProperty('role'))) {
      routeParams.push(`role=${params.role ? params.role : role}`);
    }

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/utilisateurs?${routeParams.join('&')}`;
  }, [role, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    setRedirect(null);
    setIsLoading(true);
    api.users.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          role,
        },
      },
      data => {
        setUsers(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, role]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="user-list">
      <div className="user-list_nav">
        <div className="user-list_search">
          <input
            className="user-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des utilisateurs"
            value={searchInput}
          />
          <Select
            name="role"
            options={[
              {
                label: 'Tous les rôles',
                value: '',
              },
              {
                label: 'Administrateur',
                value: 'admin',
              },
              {
                label: 'Utilisateur',
                value: 'user',
              },
            ]}
            onChange={e => setRedirect(generateRouteParams({role: e.target.value, page: 1}))}
            label=""
            value={role}
          />
        </div>

        <div className="user-list_pagination">
          <span className="user-list_count">Utilisateurs {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="user-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="user-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <>
          <div className="user-list_table">
            <div className="user-list_header">
              <div
                className="user-list_header-cell user-list_header-cell--sortable user-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="user-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="user-list_header-cell user-list_header-cell--sortable user-list_cell--date"
                onClick={() => changeSort('updatedAt')}
              >
                Date de mise à jour
                {sortBy === 'updatedAt' && (
                  <i className="user-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div className="user-list_header-cell user-list_cell--username">
                <div
                  className="user-list_sub_cell user-list_header-cell--sortable user-list_sub_cell--username"
                  onClick={() => changeSort('username')}
                >
                  Nom d'utilisateur
                  {sortBy === 'username' && (
                    <i className="user-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>
                <div
                  className="user-list_sub_cell user-list_header-cell--sortable user-list_sub_cell--email"
                  onClick={() => changeSort('email')}
                >
                  Email
                  {sortBy === 'email' && (
                    <i className="user-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>
              </div>

              <div
                className="user-list_header-cell user-list_header-cell--sortable user-list_cell--role"
                onClick={() => changeSort('role')}
              >
                Rôle
                {sortBy === 'role' && (
                  <i className="user-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="user-list_header-cell user-list_header-cell--sortable user-list_cell--active"
                onClick={() => changeSort('active')}
              >
                Actif
                {sortBy === 'active' && (
                  <i className="user-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

            </div>

            {users.length > 0 && (
              <div>
                {users.map(user => (
                  <div
                    className="user-list_row"
                    key={user.id}
                  >
                    <Link
                      className="user-list_cell user-list_cell--id"
                      title="Éditer l'utilisateur"
                      to={`/utilisateurs/${user.id}/modifier`}
                    >
                      {user.id}
                    </Link>

                    <Link
                      className="user-list_cell user-list_cell--date"
                      title="Éditer l'utilisateur"
                      to={`/utilisateurs/${user.id}/modifier`}
                    >
                      {moment(user.updatedAt).format('DD/MM/YYYY - HH:mm')}
                    </Link>

                    <Link
                      className="user-list_cell"
                      title="Éditer l'utilisateur"
                      to={`/utilisateurs/${user.id}/modifier`}
                    >
                        <div className="user-list_sub_cell user-list_sub_cell--username">
                          {user.username}
                        </div>
                        <div className="user-list_sub_cell user-list_sub_cell--email">
                          {user.email}
                        </div>
                    </Link>

                    <Link
                      className="user-list_cell user-list_cell--role"
                      title="Éditer l'utilisateur"
                      to={`/utilisateurs/${user.id}/modifier`}
                    >
                      <UserRoleDisplay roleLabel={rolesToLabel(user.roles)} />
                    </Link>

                    <div className="user-list_cell user-list_cell--active">
                      <ActiveToggle user={user} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </>
      )}

      {users.length === 0 && !isLoading && (
        <p className="user-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default UserList;
