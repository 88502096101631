import React, { useState } from 'react';
import Button from '../button';
import ImageList from '../image-list';
import ImageUploader from '../image-uploader';
import PageHeader from '../page-header';
import './image-picker.css';

const displayButtons = false;

const ImagePicker = props => {
  const [tab, setTab] = useState(() => props.multi ? 'list' : 'upload');

  return (
    <div className="image-picker">
      <div className="image-picker_overlay" onClick={() => props.onCancel()}></div>
      <div className="image-picker_wrapper">
        <div className="image-picker_container">
          <div className="image-picker_header">
            <PageHeader
              title="Insérer une image"
            />
            <div className="image-pikcer_tabs">
              {displayButtons && <Button
                className="image-picker_tab"
                type="button"
                label="Choisir dans la collection"
                onClick={() => setTab('list')}
              />}

              {displayButtons && <Button
                className="image-picker_tab"
                type="button"
                theme="success"
                label="Envoyer une nouvelle image"
                onClick={() => setTab('upload')}
              />}
            </div>
          </div>

          <div className="image-picker_content">
            {tab === 'upload' && (
              <ImageUploader onUpload={image => {
                if (props.multi) {
                  setTab('list');
                } else {
                  props.onPick([image]);
                  props.onCancel();
                }
              }} />
            )}

            {tab === 'list' && (
              <ImageList
                multi={props.multi}
                onSelect={images => {
                  props.onPick(images);
                  props.onCancel();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ImagePicker.defaultProps = {
  multi: false,
};

export default ImagePicker;
