import React, { createContext, useContext, useEffect, useState } from 'react';
import authClient from '../lib/auth-client';

const AuthContext = createContext();

const getUserFromLocalStorage = () => {
  if (!localStorage.getItem('user')) {
    return null;
  }

  return JSON.parse(localStorage.getItem('user'));
};

const AuthProvider = props => {
  const [user, setUser] = useState(getUserFromLocalStorage());

  const login = (form, errorCb) => {
    authClient.login(
      form,
      data => {
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data));
      },
      err => {
        errorCb(err);
        localStorage.removeItem('user');
      }
    );
  };

  const logout = () => {
    authClient.logout(
      () => {
        setUser(null);
        localStorage.removeItem('user');
      },
      err => {
        console.error('authClient.logout() error FROM auth-context.js', err);
      },
    );
  };

  const refresh = () => {
    authClient.me(
      data => {
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data));
      },
      () => {
        setUser(null);
        localStorage.removeItem('user');
      },
    );
  };

  useEffect(() => {
    document.addEventListener('refreshUser', refresh);

    return () => {
      document.removeEventListener('refreshUser', refresh);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('logout', logout);

    return () => {
      document.removeEventListener('logout', logout);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, login, logout }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
