import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './link-button.css';

const generateClassName = props => {
  let className = 'link-button';

  className = props.className ? className.concat(' ', props.className) : className;
  className = props.theme ? className.concat(' link-button--', props.theme) : className;
  className = props.size ? className.concat(' link-button--', props.size) : className;
  className = !props.icon ? className.concat(' link-button--no-icon') : className;

  return className;
};

const LinkButton = props => (
  <Link
    className={generateClassName(props)}
    to={props.to}
  >
    {props.icon && (
      <i className="link-button_icon material-icons">{props.icon}</i>
    )}
    <span className="link-button_label">{props.label}</span>
  </Link>
);

LinkButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  to: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default LinkButton;
