import React, { createContext, useContext, useState } from 'react';

const UIContext = createContext();

const UIProvider = props => {
  const [isNavActive, setIsNavActive] = useState(false);

  return <UIContext.Provider value={[isNavActive, setIsNavActive]} {...props} />;
};

const useUI = () => {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }

  return context;
};

export { UIProvider, useUI };
