import React, { useState } from 'react';
import Button from '../button';
import Input from '../input';
import Loader from '../loader';
import api from '../../api';
import './image-uploader.css';

const defaultImage = {
  caption: '',
  credit: '',
  imageFile: '',
  imageFileFile: null,
};

const defaultErrors = {
  caption: [],
  credit: [],
  global: [],
  imageFile: [],
};

const ImageUploader = props => {
  const [image, setImage] = useState(defaultImage);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = e => {
    setImage({
      ...image,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const validateFile = () => {
    if (image.imageFile === '' || image.imageFileFile === null) {
      setErrors({
        ...errors,
        imageFile: ['Veuillez sélectionner une image à envoyer'],
      });

      return false;
    }

    if (image.imageFileFile.size > (1024 * 1024 * process.env.REACT_APP_UPLOAD_MAX_FILESIZE)) {
      setErrors({
        ...errors,
        imageFile: ['Fichier trop volumineux'],
      });

      return false;
    }

    return true;
  }

  const submit = () => {
    const formData = new FormData();

    formData.append('caption', image.caption);
    formData.append('credit', image.credit);
    formData.append('imageFile', image.imageFileFile);

    if (validateFile()) {
      setIsLoading(true);
      api.images.post(
        formData,
        data => props.onUpload(data),
        err => {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          
          if (err.response) {
            if (Array.isArray(err.response.data.violations)) {
              err.response.data.violations.map(error => {
                if (Array.isArray(newErrors[error.propertyPath])) {
                  return newErrors[error.propertyPath].push(error.title);
                }
  
                return newErrors['global'].push(error.title);
              });
            } else {
              newErrors['global'].push('Une erreur s\'est produite.');
              console.error('<ImageUploader>: Unable to parse API error response data\n', err.response.data);
            }
  
            setErrors(newErrors);
          } else {
            newErrors['global'].push('Une erreur s\'est produite.');
            console.error('<ImageUploader>: Unknown API error\n', err);
          }
  
          setImage({
            ...image,
            imageFile: '',
            imageFileFile: null,
          });
          setIsLoading(false);
        },
      );
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="image-uploader">
      {errors.global.length > 0 && errors.global.map(error => (
        <span className="image-uploader_error" key={error}>{error}</span>
      ))}

      <Input
        accept="image/*"
        errors={errors.imageFile}
        label="Choisir un fichier"
        name="imageFile"
        onChange={e => {
          setImage({
            ...image,
            imageFile: e.target.value,
            imageFileFile: e.target.files[0],
          });
          setErrors({
            ...errors,
            imageFile: [],
          });
        }}
        tips={`Taille max. de l'image : ${process.env.REACT_APP_UPLOAD_MAX_FILESIZE} Mo`}
        type="file"
        value={image.imageFile}
      />

      <Input
        errors={errors.caption}
        label="Description"
        name="caption"
        onChange={handleChange}
        type="text"
        value={image.caption}
      />

      <Input
        errors={errors.credit}
        label="Crédits"
        name="credit"
        onChange={handleChange}
        type="text"
        value={image.credit}
      />

      <br />

      <Button
        label="Envoyer"
        theme="success"
        type="button"
        onClick={submit}
      />
    </div>
  );
};

export default ImageUploader;
