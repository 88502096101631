import React from 'react';
import { Link } from 'react-router-dom';
import './paginator.css';

const RANGE = 2;

const getPageNumbers = (currentPage, maxPage) => {
  let pageNumbers = [];
  let firstPageNumber;
  let lastPageNumber;

  if (maxPage < 2 * RANGE + 1) {
    firstPageNumber = 1;
    lastPageNumber = maxPage;
  } else {
    firstPageNumber = currentPage - RANGE;
    firstPageNumber =
      firstPageNumber < maxPage - 2 * RANGE
        ? firstPageNumber
        : maxPage - 2 * RANGE;
    firstPageNumber = firstPageNumber < 1 ? 1 : firstPageNumber;
    lastPageNumber = currentPage + RANGE;
    lastPageNumber =
      lastPageNumber > 1 + 2 * RANGE ? lastPageNumber : 1 + 2 * RANGE;
    lastPageNumber = lastPageNumber > maxPage ? maxPage : lastPageNumber;
  }

  for (
    let pageNumber = firstPageNumber;
    pageNumber <= lastPageNumber;
    pageNumber++
  ) {
    pageNumbers.push(pageNumber);
  }

  return pageNumbers;
};

const Paginator = ({ currentPage, maxPage, routePattern }) => (
  <div className="paginator">
    <Link
      className="paginator_button"
      disabled={currentPage === 1}
      to={routePattern.replace('[page]', '1')}
    >
      <div className="paginator_icon">
        <i className="material-icons">first_page</i>
      </div>
    </Link>
    {getPageNumbers(Number(currentPage, 10), Number(maxPage, 10)).map(pageNumber => (
      <Link
        className={`paginator_button${Number(currentPage, 10) === Number(pageNumber, 10) ? ' paginator_button--disabled' : ''}`}
        key={pageNumber}
        to={routePattern.replace('[page]', `${pageNumber}`)}
      >
        <div>{pageNumber}</div>
      </Link>
    ))}
    <Link
      className="paginator_button"
      disabled={currentPage === maxPage}
      to={routePattern.replace('[page]', `${maxPage}`)}
    >
      <div className="paginator_icon">
        <i className="material-icons">last_page</i>
      </div>
    </Link>
  </div>
);

export default Paginator;