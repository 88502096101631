import React from 'react';
import {
  useParams,
} from 'react-router-dom';
import CategoryForm from '../components/category-form';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import ButtonLink from '../components/button-link';
import PageHeader from '../components/page-header';
import './page.css';

const CategoryPage = () => {
  const { id } = useParams();

  return (
    <Layout>
      <div className="page">
        <PageHeader
          title={`${id ? 'Modifier' : 'Créer'} une catégorie`}
        />

        <div className="page_actions">
          <LinkButton
            className="page_action-button"
            icon="arrow_back"
            label="Retour"
            to="/categories"
            theme="danger"
          />

          {id &&
            <ButtonLink
              className="page_action-button"
              icon="remove_red_eye"
              label="Voir en ligne"
              href={`${process.env.REACT_APP_API_HOST}/thematique/${id}`}
              theme="info"
              target="_blank"
            />
          }
        </div>

        <CategoryForm id={parseInt(id, 10)} />
      </div>
    </Layout>
  );
};

export default CategoryPage;
