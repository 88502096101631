/* global ePub */

import React, { useEffect, useState } from 'react';
import Button from '../button';
import DocumentPicker from '../document-picker';
import api from '../../api';
import './document-input.css';

const DocumentInput = props => {
  const [document, setDocument] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (props.value) {
      api.documents.get(
        props.value,
        data => setDocument(data),
      );
    } else {
      setDocument(null);
    }
  }, [props.value]);

  useEffect(() => {
    if (!document || !document.name.endsWith('.epub')) {
      return;
    }

    const bookEpubDocument = ePub(`${process.env.REACT_APP_API_HOST}/api/v1/document/file/${document.id}.epub`);
    const rendition = bookEpubDocument.renderTo(`area_${document.id}`, {
      width: 900,
      height: 600,
      manager: "continuous",
      flow: "scrolled",
    });
    rendition.display();

    return () => {
      bookEpubDocument.destroy();
    }
  }, [document]);

  return (
    <div className="document-input">
      <span className="document-input_label">{props.label}</span>

      {active && (
        <DocumentPicker
          onCancel={() => setActive(false)}
          onPick={documents => props.onChange({
            target: {
              name: props.name,
              value: documents[0].id,
            },
          })}
        />
      )}

      <div className="document-input_input">
        {document && document.name.endsWith('.pdf') && (
          <object
            className="document-input_preview"
            data={`${process.env.REACT_APP_API_HOST}${document.name}`}
          >
            document.name
          </object>
        )}

        {document && document.name.endsWith('.epub') && (
          <div className="document-input_epub-container">
            <div id={`area_${document.id}`}></div>
          </div>
        )}

        <div className="document-input_actions">
          {document && (
            <Button
              label='Selectionner un autre document'
              onClick={() => setActive(true)}
              size="sm"
              type="button"
            />
          )}

          <Button
            label={props.value === null ? 'Choisir un document' : 'Supprimer le document'}
            onClick={() => {
              if (props.value === null) {
                setActive(true);
              } else {
                props.onChange({
                  target: {
                    name: props.name,
                    value: null,
                  },
                });
              }
            }}
            size="sm"
            theme={props.value === null ? null : 'danger'}
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

DocumentInput.defaultProps = {
  label: 'Document',
};

export default DocumentInput;
