import articles from './articles';
import authors from './authors';
import categories from './categories';
import images from './images';
import tags from './tags';
import users from './users';
import staticPages from './static-pages';
import folders from './folders';
import comments from './comments';
import libraryBooks from './library-books';
import libraryBookVersions from './library-book-versions';
import documents from './documents';
import embed from './embed';
import newsletters from './newsletters';
import mailCampaigns from './mail-campaigns';
import ads from './ads';
import baseExport from './base-export';
import previewLinks from './preview-links';
import highlightedLinks from './highlighted-links';
import eans from './eans';

const submodules = {
  articles,
  authors,
  categories,
  images,
  tags,
  users,
  staticPages,
  folders,
  comments,
  libraryBooks,
  libraryBookVersions,
  documents,
  embed,
  newsletters,
  mailCampaigns,
  ads,
  baseExport,
  previewLinks,
  highlightedLinks,
  eans,
};

export default submodules;
