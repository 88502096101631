import React from 'react';
import PropTypes from 'prop-types';
import './button.css';

const generateClassName = (props) => {
  let className = 'button';

  className = props.className ? className.concat(' ', props.className) : className;
  className = props.theme ? className.concat(' button--', props.theme) : className;
  className = props.size ? className.concat(' button--', props.size) : className;
  className = !props.icon ? className.concat(' button--no-icon') : className;

  return className;
};

const Button = ({ className, icon, label, size, theme, ...buttonProps }) => (
  <button
    className={generateClassName({ className, icon, size, theme })}
    {...buttonProps}
  >
    {icon && (
      <i className="button_icon material-icons">{icon}</i>
    )}
    <span className="button_label">{label}</span>
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  theme: PropTypes.string,
};

export default Button;
