import { create } from 'axios';
import cleanDeep from 'clean-deep';

const axios = create({
  withCredentials: true,
});

axios.interceptors.request.use((config) => {
  if ((config.method === 'post' || config.method === 'put') && config.data && !(config.data instanceof FormData)) {
    config.data = cleanDeep(config.data, {
      emptyArrays: false,
      nullValues: false,
    });
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        console.error('Received 401 / 403 response from API, loging out.');
        document.dispatchEvent(new CustomEvent('logout'));
      }
    }

    return Promise.reject(error);
  },
);

export default axios;
