import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MailCampaignForm from '../components/mail-campaign-form';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import ButtonLink from '../components/button-link';
import './page.css';
import api from '../api';
import Loader from '../components/loader';
import { Redirect } from 'react-router-dom';

const MailCampaignPage = () => {
  const { id, fromId } = useParams();

  const [previewLink, setPreviewLink] = useState(null);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    setRedirect('');
    setIsDuplicating(false);
    if (id) {
      api.previewLinks.mailCampaign(
        id,
        link => {
          setPreviewLink(link);
        },
      );
    }
  }, [id, fromId]);

  const duplicate = (fromId) => {
    if (fromId) {
      setIsDuplicating(true);
      setRedirect(`/campagnes/dupliquer/${fromId}`);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Layout>
      <div className="page">
        <PageHeader
          title={`${id ? 'Modifier' : 'Créer'} une campagne`}
        />

        <div className="page_actions">
          <LinkButton
            className="page_action-button"
            icon="arrow_back"
            label="Retour"
            to="/campagnes"
            theme="danger"
          />

          {id &&
            <ButtonLink
              className="page_action-button"
              icon="content_copy"
              label="Dupliquer"
              href=""
              theme="info"
              target="_blank"
              onClick={(e) => { e.preventDefault(); duplicate(id) }}
            />
          }

          {id && previewLink &&
            <ButtonLink
              className="page_action-button"
              icon="remove_red_eye"
              label="Voir en ligne"
              href={`${process.env.REACT_APP_API_HOST}${previewLink}`}
              theme="info"
              target="_blank"
            />
          }
        </div>

        {!isDuplicating &&
          <MailCampaignForm id={parseInt(id ?? '0', 10)} fromId={parseInt(fromId ?? '0', 10)}/>
        }

        {isDuplicating &&
          <Loader />
        }
      </div>
    </Layout>
  );
};

export default MailCampaignPage;
