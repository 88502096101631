import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Select from '../select';
import Paginator from '../paginator';
import api from '../../api';
import './folder-list.css';

let searchUpdateTimeout = null;

const statusesLabels = {
  draft: 'En préparation',
  published: 'Publié',
};

const FolderStatus = ({ status }) => (
  <span className={`folder-list_status-badge folder-list_status-badge--${status}`}>
    {statusesLabels[status]}
  </span>
);

const FolderList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const search = query.get('search') ? query.get('search') : '';
  const status = query.get('status') ? query.get('status') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'updatedAt';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.status || (status && !params.hasOwnProperty('status'))) {
      routeParams.push(`status=${params.hasOwnProperty('status') ? params.status : status}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/dossiers?${routeParams.join('&')}`;
  }, [status, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [folders, setFolders] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const refreshList = useCallback(() => {
    setRedirect(null);
    setIsLoading(true);
    api.folders.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          status,
        },
      },
      data => {
        setFolders(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, status]);
  
  const NewsletterToggle = ({ folder, refreshList }) => {
    const [isLoading, setIsLoading] = useState(false);
  
    const generateClassname = () => {
      let className = 'folder-list_newsletter-toggle';
  
      if (folder.includedInNewsletter) {
        className += ' folder-list_newsletter-toggle--active';
      }
  
      if (isLoading) {
        className += ' folder-list_newsletter-toggle--loading';
      }

      return className;
    };
  
    const toggle = () => {
      setIsLoading(true);
      api.folders.put(
        {
          id: folder.id,
          folder: {
            includedInNewsletter: !folder.includedInNewsletter,
          },
        },
        () => {
          refreshList();
        },
        () => {
          setIsLoading(false);
        },
      );
    };
  
    return (
      <span
        className={generateClassname()}
        onClick={e => {
          e.preventDefault();
  
          if (!isLoading) {
            toggle();
          }
        }}
      >
        <i className="folder-list_newsletter-toggle-icon material-icons">{folder.includedInNewsletter ? 'toggle_on' : 'toggle_off'}</i>
      </span>
    );
  };
  

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    setRedirect(null);
    setIsLoading(true);
    api.folders.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          status,
        },
      },
      data => {
        setFolders(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, status]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="folder-list">
      <div className="folder-list_nav">
        <div className="folder-list_search">
          <input
            className="folder-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des dossiers"
            value={searchInput}
          />

          <Select
            name="status"
            options={[
              {
                label: 'Tous les statuts',
                value: '',
              },
              {
                label: 'En préparation',
                value: 'draft',
              },
              {
                label: 'Publié',
                value: 'published',
              },
            ]}
            onChange={e => setRedirect(generateRouteParams({status: e.target.value, page: 1}))}
            label=""
            value={status}
          />
        </div>

        <div className="folder-list_pagination">
          <span className="folder-list_count">Dossiers {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="folder-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="folder-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <>
          <div className="folder-list_table">
            <div className="folder-list_header">
              <div
                className="folder-list_header-cell folder-list_header-cell--sortable folder-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="folder-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="folder-list_header-cell folder-list_header-cell--sortable folder-list_cell--date"
                onClick={() => changeSort('updatedAt')}
              >
                Date de mise à jour
                {sortBy === 'updatedAt' && (
                  <i className="folder-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="folder-list_header-cell folder-list_header-cell--sortable folder-list_cell--title"
                onClick={() => changeSort('slug')}
              >
                Titre
                {sortBy === 'slug' && (
                  <i className="folder-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="folder-list_header-cell folder-list_header-cell--sortable folder-list_cell--views"
                onClick={() => changeSort('nbView')}
              >
                Vues
                {sortBy === 'nbView' && (
                  <i className="folder-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="folder-list_header-cell folder-list_header-cell--sortable folder-list_cell--newsletter folder-list_cell--active"
                onClick={() => changeSort('includedInNewsletter')}
              >
                Newsletter
                {sortBy === 'includedInNewsletter' && (
                  <i className="folder-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div className="folder-list_header-cell folder-list_cell--status">
                Statut
              </div>
            </div>

            {folders.length > 0 && (
              <div>
                {folders.map(folder => (
                  <div
                    className="folder-list_row"
                    key={folder.id}
                  >
                    <Link
                      className="folder-list_cell folder-list_cell--id"
                      title="Éditer le dossier"
                      to={`/dossiers/${folder.id}/modifier`}
                    >
                      {folder.id}
                    </Link>

                    <Link
                      className="folder-list_cell folder-list_cell--date"
                      title="Éditer le dossier"
                      to={`/dossiers/${folder.id}/modifier`}
                    >
                      {moment(folder.updatedAt).format('DD/MM/YYYY - HH:mm')}
                    </Link>

                    <Link
                      className="folder-list_cell folder-list_cell--title"
                      title="Éditer le dossier"
                      to={`/dossiers/${folder.id}/modifier`}
                    >
                      {folder.title}
                    </Link>

                    <Link
                      className="folder-list_cell folder-list_cell--views"
                      title="Nombre de vues"
                      to={`/dossiers/${folder.id}/modifier`}
                    >
                      {folder.nbView}
                    </Link>

                    <div className="folder-list_cell folder-list_cell--newsletter">
                      <NewsletterToggle folder={folder} refreshList={refreshList} />
                    </div>

                    <div className="folder-list_cell folder-list_cell--status">
                      <FolderStatus status={folder.status} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </>
      )}

      {folders.length === 0 && !isLoading && (
        <p className="folder-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default FolderList;
