import axios from './config';

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/ads`, { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/ad/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.ad.get() received error from api request:', err),
  );
};

const post = (params, successCb, errorCb) => {
  if (params.ad.active === '1') {
    params.ad.active = true;
  }
  if (params.ad.active === '0') {
    params.ad.active = false;
  }

  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/ad`,
    params.ad,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const put = (params, successCb, errorCb) => {
  if (params.ad.active === '1') {
    params.ad.active = true;
  }
  if (params.ad.active === '0') {
    params.ad.active = false;
  }

  let { ad } = params;

  axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/ad/${params.id}`,
    ad,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const endpoints = {
  list,
  get,
  post,
  put,
};

export default endpoints;