import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import CategoryInput from '../category-input';
import Select from '../select';
import Form from '../form';
import Input from '../input';
import Loader from '../loader';
import './category-form.css';
import SummaryEditor from '../summary-editor/summary-editor';

const defaultCategory = {
  name: '',
  description: '',
  parentId: null,
  menuDisplay: 'none',
  priority: 0,
};

const defaultErrors = {
  name: [],
  description: [],
  parentId: [],
  menuDisplay: [],
  priority: [],
};

const CategoryForm = props => {
  const [category, setCategory] = useState(defaultCategory);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    let value = e.target.value;
    if (e.target.name === 'description') {
      value = value || null;
    }
    setCategory({
      ...category,
      [e.target.name]: value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.categories.put : api.categories.post;
    const apiParams = props.id ? { id: props.id, category } : { category };

    category.priority = parseInt(category.priority, 10);

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/categories');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.categories.get(
        props.id,
        category => {
          let newCategory = {
            ...defaultCategory,
            ...category,
            parentId: category.parent ? category.parent.id : null,
          };

          delete newCategory.children;
          setCategory(newCategory);
          setIsLoading(false);
        },
      );
    } else {
      setCategory(defaultCategory);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="category-form">
      <Form
        className="category-form_form"
        onSubmit={handleSubmit}
      >
        <div className="category-form_column">
          <Input
            errors={errors.name}
            label="Nom"
            name="name"
            onChange={handleChange}
            type="text"
            value={category.name}
            disabled={props.id ? true : false}
          />

          <CategoryInput
            errors={errors.parentId}
            label="Catégorie parente"
            name="parentId"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={category.parentId}
            filterForParentOf={category.id}
          />

          <Select
            errors={errors.menuDisplay}
            label="Affichage menu"
            name="menuDisplay"
            options={[
              {
                label: 'Aucun',
                value: 'none',
              },
              {
                label: 'Aucun, même en sous-menu',
                value: 'none-no-sub',
              },
              {
                label: 'Menu principal',
                value: 'top',
              },
              {
                label: 'Menu latéral',
                value: 'side',
              },
            ]}
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={category.menuDisplay}
          />

          <Input
            errors={errors.priority}
            label="Priorité"
            name="priority"
            onChange={handleChange}
            type="number"
            value={category.priority}
          />

          <SummaryEditor
            errors={errors.description}
            label="Description"
            name="description"
            onChange={handleChange}
            value={category.description}
          />

          <Button
            className="category-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} la catégorie`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

CategoryForm.propTypes = {
  id: PropTypes.number,
};

export default CategoryForm;
