import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import Input from '../input';
import TextArea from '../textarea';
import Checkbox from '../checkbox';
import Select from '../select';
import ImageInput from '../image-input';
import Loader from '../loader';
import './ad-form.css';

const defaultAd = {
  imageId: null,
  type: 'html',
  position: 'ga-1',
  htmlContent: '',
  nbView: '',
  nbClick: '',
  campaignName: '',
  ownerName: 'Générique',
  imageLink: '',
  active: false,
};

const defaultErrors = {
  imageId: [],
  type: [],
  position: [],
  htmlContent: [],
  nbView: [],
  nbClick: [],
  campaignName: [],
  ownerName: [],
  imageLink: [],
  active: [],
};

const AdForm = props => {
  const [ad, setAd] = useState(defaultAd);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    let value = e.target.value;

    setAd({
      ...ad,
      [e.target.name]: value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.ads.put : api.ads.post;
    const apiParams = props.id ? { id: props.id, ad } : { ad };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/publicites');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.ads.get(
        props.id,
        ad => {
          let newAd = {
            ...defaultAd,
            ...ad,
            imageId: ad.image ? ad.image.id : null,
          };

          setAd(newAd);
          setIsLoading(false);
        },
      );
    } else {
      setAd(defaultAd);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="ad-form">
      <Form
        className="ad-form_form"
        onSubmit={handleSubmit}
      >
        <div className="ad-form_column">
          <Input
            errors={errors.campaignName}
            label="Nom de la campagne"
            name="campaignName"
            onChange={handleChange}
            type="text"
            value={ad.campaignName}
          />

          <Input
            errors={errors.ownerName}
            label="Diffuseur"
            name="ownerName"
            onChange={handleChange}
            type="text"
            value={ad.ownerName}
          />

          <Select
            errors={errors.type}
            label="Type"
            name="type"
            options={[
              {
                label: 'HTML / JS',
                value: 'html',
              },
              {
                label: 'Image',
                value: 'image',
              },
            ]}
            onChange={handleChange}
            style={{width: '100%'}}
            value={ad.type}
          />

          <Select
            errors={errors.position}
            label="Position"
            name="position"
            options={[
              {
                label: 'Desktop - Grand angle 1 (300x600)',
                value: 'ga-1',
              },
              {
                label: 'Desktop - Grand angle 2 (300x600)',
                value: 'ga-2',
              },
              {
                label: 'Desktop - Bannière (1000x90)',
                value: 'ban',
              },
              {
                label: 'Desktop - Bannière commentaires (728x90)',
                value: 'inner-ban',
              },
              {
                label: 'Desktop - Bannière in-content 1 (728x90)',
                value: 'in-content-ban-1',
              },
              {
                label: 'Desktop - Bannière in-content 2 (728x90)',
                value: 'in-content-ban-2',
              },
              {
                label: 'Desktop - Bannière in-content 3 (728x90)',
                value: 'in-content-ban-3',
              },
              {
                label: 'Desktop - Bannière in-content 4 (728x90)',
                value: 'in-content-ban-4',
              },
              {
                label: 'Desktop - Bannière in-content 5 (728x90)',
                value: 'in-content-ban-5',
              },
              {
                label: 'Desktop - Bannière in-content 6 (728x90)',
                value: 'in-content-ban-6',
              },
              {
                label: 'Desktop - Bannière in-content 7 (728x90)',
                value: 'in-content-ban-7',
              },
              {
                label: 'Desktop - Bannière in-content 8 (728x90)',
                value: 'in-content-ban-8',
              },
              {
                label: 'Desktop - Bannière in-content 9 (728x90)',
                value: 'in-content-ban-9',
              },
              {
                label: 'Desktop - Bannière in-content 10 (728x90)',
                value: 'in-content-ban-10',
              },
              {
                label: 'Mobile - Pavé 1 (350x300)',
                value: 'mb-1',
              },
              // {
              //   label: 'Mobile - Pavé 2 (350x300)',
              //   value: 'mb-2',
              // },
              {
                label: 'Mobile - Pavé commentaires (350x300)',
                value: 'mb-3',
              },
              {
                label: 'Mobile - In-content 1 (350x300)',
                value: 'mb-in-content-1',
              },
              {
                label: 'Mobile - In-content 2 (350x300)',
                value: 'mb-in-content-2',
              },
              {
                label: 'Mobile - In-content 3 (350x300)',
                value: 'mb-in-content-3',
              },
              {
                label: 'Mobile - In-content 4 (350x300)',
                value: 'mb-in-content-4',
              },
              {
                label: 'Mobile - In-content 5 (350x300)',
                value: 'mb-in-content-5',
              },
              {
                label: 'Mobile - In-content 6 (350x300)',
                value: 'mb-in-content-6',
              },
              {
                label: 'Mobile - In-content 7 (350x300)',
                value: 'mb-in-content-7',
              },
              {
                label: 'Mobile - In-content 8 (350x300)',
                value: 'mb-in-content-8',
              },
              {
                label: 'Mobile - In-content 9 (350x300)',
                value: 'mb-in-content-9',
              },
              {
                label: 'Mobile - In-content 10 (350x300)',
                value: 'mb-in-content-10',
              },
              {
                label: 'Newsletter - Bannière (600x300)',
                value: 'nl',
              },
            ]}
            onChange={handleChange}
            style={{width: '100%'}}
            value={ad.position}
          />

          {ad.type === 'html' && <TextArea
            errors={errors.htmlContent}
            label="Contenu HTML / JS"
            name="htmlContent"
            onChange={handleChange}
            value={ad.htmlContent}
          />}

          {ad.type === 'image' &&
            <ImageInput
              errors={errors.imageId}
              label="Image"
              name="imageId"
              onChange={handleChange}
              value={ad.imageId}
            />
          }

          {ad.type === 'image' &&
            <Input
              errors={errors.imageLink}
              label="Lien"
              name="imageLink"
              onChange={handleChange}
              type="text"
              value={ad.imageLink || ''}
            />
          }

          <Checkbox
            errors={errors.active}
            label="Actif"
            name="active"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={ad.active}
          />

          <Button
            className="ad-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} la publicité`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

AdForm.propTypes = {
  id: PropTypes.number,
};

export default AdForm;
