import React from 'react';
import { AuthProvider } from './auth-context';
import { UserProvider } from './user-context';
import { UIProvider } from './ui-context';

const AppProviders = ({ children }) => (
  <AuthProvider>
    <UserProvider>
      <UIProvider>
        {children}
      </UIProvider>
    </UserProvider>
  </AuthProvider>
);

export default AppProviders;
