import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import BaseExportForm from '../components/base-export-form';
import './page.css';

const ExportsPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Exports"
        description="Exporter des données."
      />

    <BaseExportForm />

    </div>
  </Layout >
);

export default ExportsPage;
