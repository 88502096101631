import React, { useEffect, useState } from 'react';
import api from '../../api';
// import './category-input.css';

const TagInput = props => {
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, SetError] = useState(null);

  useEffect(() => {
    api.tags.list(
      {},
      data => {
        setTags(data.items);
        setIsLoading(false);
      },
      err => {
        SetError(err);
        setIsLoading(false);
      },
    );
  }, []);

  return (
    <label
      className="category-input"
      style={props.style}
    >
      {props.label && (
        <span className="category-input_label">{props.label}</span>
      )}

      {props.tips && (
        <span className="category-input_tips">{props.tips}</span>
      )}

      {error !== null && (
        <span
          className="category-input_error"
          key={error}
        >
          {error}
        </span>
      )}

      {props.errors && props.errors.map(propsError => (
        <span
          className="category-input_error"
          key={propsError}
        >
          {propsError}
        </span>
      ))}

      <select
        className={`category-input_input${props.errors.length > 0 ? ' category-input_input--error' : ''}`}
        name={props.name}
        onChange={e => props.onChange({
          target: {
            name: e.target.name,
            value: e.target.value !== '' ? parseInt(e.target.value, 10) : null,
          },
        })}
        value={props.value ? props.value : ''}
      >
        <option value="">Aucun</option>

        {isLoading && (
          <option disabled value="">Chargement ...</option>
        )}

        {!isLoading && tags.length === 0 && (
          <option disabled value="">Aucun tag trouvé ...</option>
        )}

        {!isLoading && tags.length > 0 && tags.map(tag => (
            <option
              key={tag.id}
              value={tag.id}
            >
              {tag.label}
            </option>
        ))}
      </select>
    </label>
  );
};

TagInput.defaultProps = {
  errors: [],
  style: {},
  value: '',
};

export default TagInput;
