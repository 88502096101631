export const rolesLabels = [
  {
    label: 'Utilisateur',
    roles: [
      'ROLE_USER',
    ],
  },
  {
    label: 'Administrateur',
    roles: [
      'ROLE_USER',
      'ROLE_ADMIN',
    ],
  },
];

export const rolesToLabel = rolesArray => {
  const rolesString = JSON.stringify(rolesArray.sort());

  for (const rolesLabel of rolesLabels) {
    if (JSON.stringify(rolesLabel.roles.sort()) === rolesString) {
      return rolesLabel.label;
    }
  }

  return rolesArray.join(', ');
};

export const rolesFromLabel = label => {
  for (const rolesLabel of rolesLabels) {
    if (rolesLabel.label === label) {
      return rolesLabel.roles;
    }
  }

  return [];
};
