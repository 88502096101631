import axios from './config';

const twitter = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/media-embed/twitter`,
    { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const endpoints = {
  twitter,
};

export default endpoints;
