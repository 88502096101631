import axios from './config';
import { cleanContent } from '../lib/editor-embed';

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/folders`, { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/folder/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.folder.get() received error from api request:', err),
  );
};

const post = (params, successCb, errorCb) => {
  let { folder } = params;

  if (folder.content && folder.content.trim()) {
    folder.content = cleanContent(folder.content);
  }

  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/folder`,
    params.folder,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const put = (params, successCb, errorCb) => {
  let { folder } = params;

  if (folder.content && folder.content.trim()) {
    folder.content = cleanContent(folder.content);
  }

  axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/folder/${params.id}`,
    folder,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const endpoints = {
  list,
  get,
  post,
  put,
};

export default endpoints;
