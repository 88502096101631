import axios from './config';

const list = successCb => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/documents`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.documents.list() received error from api request:', err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/document/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.documents.get() received error from api request:', err),
  );
};

const post = (params, successCb, errorCb) => {
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/document`,
    params,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

// const put = (params, successCb, errorCb) => {
//   let { document } = params;

//   axios.put(
//     `${process.env.REACT_APP_API_BASE_URL}/document/${params.id}`,
//     document,
//   ).then(
//     res => successCb(res.data),
//   ).catch(
//     errorCb,
//   );
// };

const endpoints = {
  list,
  get,
  post,
  // put,
};

export default endpoints;
