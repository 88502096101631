import React from 'react';
import CommentList from '../components/comment-list';
import Layout from '../components/layout';
// import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const CommentsPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Commentaires"
        description="Créer, éditer ou supprimer des commentaires."
      />

      {/* <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer un commentaire"
          to="/commentaires/creer"
          theme="success"
        />
      </div> */}

      <CommentList />
    </div>
  </Layout >
);

export default CommentsPage;
