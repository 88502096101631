import React from 'react';
import AuthorList from '../components/author-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const AuthorsPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Auteurs"
        description="Créer, éditer ou supprimer des auteurs."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer un auteur"
          to="/auteurs/creer"
          theme="success"
        />
      </div>

      <AuthorList />
    </div>
  </Layout >
);

export default AuthorsPage;
