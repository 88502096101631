import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import './page.css';

const ImagesPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Images"
        description="Créer, éditer ou supprimer des images."
      />
      <p>Work in progress...</p>
    </div>
  </Layout >
);

export default ImagesPage;
