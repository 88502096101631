import axios from './config';

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/authors`,
    { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/author/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.author.get() received error from api request:', err),
  );
};

const post = (params, successCb, errorCb) => {
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/author`,
    params.author,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const put = (params, successCb, errorCb) => {
  let { author } = params;

  axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/author/${params.id}`,
    author,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const endpoints = {
  list,
  get,
  post,
  put,
};

export default endpoints;