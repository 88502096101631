import React from 'react';
import PropTypes from 'prop-types';
import './button-link.css';

const generateClassName = (props) => {
  let className = 'button-link';

  className = props.className ? className.concat(' ', props.className) : className;
  className = props.theme ? className.concat(' button-link--', props.theme) : className;
  className = props.size ? className.concat(' button-link--', props.size) : className;
  className = !props.icon ? className.concat(' button-link--no-icon') : className;

  return className;
};

const ButtonLink = ({ className, icon, label, size, theme, ...buttonLinkProps }) => (
  <a
    className={generateClassName({ className, icon, size, theme })}
    {...buttonLinkProps}
  >
    {icon && (
      <i className="button-link_icon material-icons">{icon}</i>
    )}
    <span className="button-link_label">{label}</span>
  </a>
);

ButtonLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  theme: PropTypes.string,
};

export default ButtonLink;
