import React from 'react';
import PropTypes from 'prop-types';

const generateClassName = props => {
  let className = 'form';

  if (props.className) {
    className = className.concat(' ', props.className);
  }

  return className;
};

const Form = props => (
  <form
    className={generateClassName(props)}
    onSubmit={props.onSubmit}
  >
    {props.children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
