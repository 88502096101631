import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import Input from '../input';
import Loader from '../loader';
import './static-page-form.css';
import Select from '../select';
import ArticleContentEditor from '../article-content-editor';

const defaultStaticPage = {
  title: '',
  type: 'error-404',
  content: '',
};

const defaultErrors = {
  title: [],
  type: [],
  content: [],
};

const StaticPageForm = props => {
  const [staticPage, setStaticPage] = useState(defaultStaticPage);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    setStaticPage({
      ...staticPage,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.staticPages.put : api.staticPages.post;
    const apiParams = props.id ? { id: props.id, staticPage } : { staticPage };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/pages-statiques');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.staticPages.get(
        props.id,
        staticPage => {
          let newStaticPage = {
            ...defaultStaticPage,
            ...staticPage,
          };

          delete newStaticPage.children;
          setStaticPage(newStaticPage);
          setIsLoading(false);
        },
      );
    } else {
      setStaticPage(defaultStaticPage);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="static-page-form">
      <Form
        className="static-page-form_form"
        onSubmit={handleSubmit}
      >
        <div className="static-page-form_column">
          <Select
            errors={errors.type}
            label="Type de page"
            name="type"
            options={[
              {
                label: 'Erreur 404',
                value: 'error-404',
              },
              {
                label: 'Erreur Interne',
                value: 'error-500',
              },
              {
                label: 'Nos Dossiers',
                value: 'folders',
              },
              {
                label: 'Contact',
                value: 'contact',
              },
              {
                label: 'Newsletter',
                value: 'newsletter',
              },
              {
                label: 'Partenaires',
                value: 'partners',
              },
              {
                label: 'Charte des commentaires',
                value: 'comments-charter',
              },
              {
                label: 'Qui sommes-nous',
                value: 'about',
              },
              {
                label: 'Mentions légales',
                value: 'legal',
              },
              {
                label: 'Cookies',
                value: 'cookies',
              },
              {
                label: 'Flux RSS',
                value: 'rss',
              },
            ]}
            onChange={handleChange}
            value={staticPage.type}
          />

          <Input
            errors={errors.title}
            label="Nom"
            name="title"
            onChange={handleChange}
            type="text"
            value={staticPage.title}
          />

          <ArticleContentEditor
            errors={errors.content}
            label="Contenu"
            name="content"
            onChange={handleChange}
            value={staticPage.content}
          />

          <Button
            className="static-page-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} la page`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

StaticPageForm.propTypes = {
  id: PropTypes.number,
};

export default StaticPageForm;
