import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Checkbox from '../checkbox';
import Form from '../form';
import Input from '../input';
import ImageInput from '../image-input';
import Loader from '../loader';
import './author-form.css';
import SummaryEditor from '../summary-editor/summary-editor';

const defaultAuthor = {
  name: '',
  email: '',
  twitterProfileUrl: '',
  instagramProfileUrl: '',
  facebookProfileUrl: '',
  description: '',
  active: true,
  pictureId: null,
};

const defaultErrors = {
  name: [],
  email: [],
  twitterProfileUrl: [],
  instagramProfileUrl: [],
  facebookProfileUrl: [],
  description: [],
  active: [],
  pictureId: [],
};

const AuthorForm = props => {
  const [author, setAuthor] = useState(defaultAuthor);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    let value = e.target.value;
    if (e.target.name === 'name') {
      value = value || null;
    } else if (e.target.name === 'email') {
      value = value || null;
    } else if (e.target.name === 'twitterProfileUrl') {
      value = value || null;
    } else if (e.target.name === 'instagramProfileUrl') {
      value = value || null;
    } else if (e.target.name === 'facebookProfileUrl') {
      value = value || null;
    } else if (e.target.name === 'description') {
      value = value || null;
    }
    setAuthor({
      ...author,
      [e.target.name]: value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.authors.put : api.authors.post;
    const apiParams = props.id ? { id: props.id, author } : { author };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/auteurs');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.authors.get(
        props.id,
        author => {
          let newAuthor = {
            ...defaultAuthor,
            ...author,
            pictureId: author.picture ? author.picture.id : null,
          };

          delete newAuthor.children;
          setAuthor(newAuthor);
          setIsLoading(false);
        },
      );
    } else {
      setAuthor(defaultAuthor);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="author-form">
      <Form
        className="author-form_form"
        onSubmit={handleSubmit}
      >
        <div className="author-form_column">
          <Input
            errors={errors.name}
            label="Nom"
            name="name"
            onChange={handleChange}
            type="text"
            value={author.name}
          />

          <ImageInput
            errors={errors.pictureId}
            label="Photo"
            name="pictureId"
            onChange={handleChange}
            value={author.pictureId}
          />

          <Input
            errors={errors.email}
            label="Email"
            name="email"
            onChange={handleChange}
            type="email"
            value={author.email}
          />

          <Checkbox
            errors={errors.active}
            label="Actif"
            name="active"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={author.active}
          />

          <Input
            errors={errors.twitterProfileUrl}
            label="Page twitter"
            name="twitterProfileUrl"
            onChange={handleChange}
            type="text"
            value={author.twitterProfileUrl}
          />

          <Input
            errors={errors.instagramProfileUrl}
            label="Page instagram"
            name="instagramProfileUrl"
            onChange={handleChange}
            type="text"
            value={author.instagramProfileUrl}
          />

          <Input
            errors={errors.facebookProfileUrl}
            label="Page facebook"
            name="facebookProfileUrl"
            onChange={handleChange}
            type="text"
            value={author.facebookProfileUrl}
          />

          <SummaryEditor
            errors={errors.description}
            label="Description"
            name="description"
            onChange={handleChange}
            value={author.description}
          />

          <Button
            className="author-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} l'auteur`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

AuthorForm.propTypes = {
  id: PropTypes.number,
};

export default AuthorForm;
