import axios from './config';

const getData = (ean, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/eans/ean/${ean}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.ean.getData() received error from api request:', err),
  );
};

const endpoints = {
  getData,
};

export default endpoints;