import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import DateInput from '../date-input';
import moment from 'moment';
import Input from '../input';
import ImageInput from '../image-input';
import Loader from '../loader';
import './newsletter-form.css';
import Select from '../select';
import ArticleContentEditor from '../article-content-editor';

const defaultNewsletter = {
  title: '',
  sendDate: '',
  mainImageId: null,
  type: 'daily',
  description: '',
};

const defaultErrors = {
  title: [],
  sendDate: [],
  mainImageId: [],
  type: [],
  description: [],
};

const NewsletterForm = props => {
  const [newsletter, setNewsletter] = useState(defaultNewsletter);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    setNewsletter({
      ...newsletter,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.newsletters.put : api.newsletters.post;
    const apiParams = props.id ? { id: props.id, newsletter } : { newsletter };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/newsletters');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.newsletters.get(
        props.id,
        newsletter => {
          let newNewsletter = {
            ...defaultNewsletter,
            ...newsletter,
            sendDate: newsletter.sendDate ? moment(newsletter.sendDate).format('YYYY-MM-DD') : '',
            mainImageId: newsletter.mainImage ? newsletter.mainImage.id : null,
          };

          setNewsletter(newNewsletter);
          setIsLoading(false);
        },
      );
    } else {
      setNewsletter(defaultNewsletter);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="newsletter-form">
      <Form
        className="newsletter-form_form"
        onSubmit={handleSubmit}
      >
        <div className="newsletter-form_column">
          <Select
            errors={errors.type}
            label="Type de newsletter"
            name="type"
            options={[
              {
                label: 'Quotidienne',
                value: 'daily',
              },
              {
                label: 'Hebdomadaire',
                value: 'weekly',
              },
            ]}
            onChange={handleChange}
            value={newsletter.type}
          />

          <Input
            errors={errors.title}
            label="Titre"
            name="title"
            onChange={handleChange}
            type="text"
            value={newsletter.title}
          />

          <DateInput
            errors={errors.sendDate}
            label="Date d'envoi"
            name="sendDate"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={newsletter.sendDate}
          />

          <ImageInput
            errors={errors.mainImageId}
            label="Image"
            name="mainImageId"
            onChange={handleChange}
            value={newsletter.mainImageId}
          />

          <ArticleContentEditor
            errors={errors.description}
            label="Contenu"
            name="description"
            onChange={handleChange}
            value={newsletter.description}
          />

          <Button
            className="newsletter-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} la newsletter`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

NewsletterForm.propTypes = {
  id: PropTypes.number,
};

export default NewsletterForm;
