import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ArticlePage from '../../pages/article-page';
import ArticlesPage from '../../pages/articles-page';
import CategoryPage from '../../pages/category-page';
import CategoriesPage from '../../pages/categories-page';
import AuthorsPage from '../../pages/authors-page';
import AuthorPage from '../../pages/author-page';
import FolderPage from '../../pages/folder-page';
import FoldersPage from '../../pages/folders-page';
import LibraryBookPage from '../../pages/library-book-page';
import LibraryBookCreatePage from '../../pages/library-book-create-page';
import LibraryBooksPage from '../../pages/library-books-page';
import LibraryBookVersionPage from '../../pages/library-book-version-page';
// import LibraryBookVersionCreatePage from '../../pages/library-book-version-create-page';
import LibraryBookVersionsPage from '../../pages/library-book-versions-page';
import HighlightedLinkPage from '../../pages/highlighted-link-page';
import HighlightedLinksPage from '../../pages/highlighted-links-page';
import StaticPagePage from '../../pages/static-page-page';
import StaticPagesPage from '../../pages/static-pages-page';
import ImagesPage from '../../pages/images-page';
import NewsletterPage from '../../pages/newsletter-page';
import NewslettersPage from '../../pages/newsletters-page';
import MailCampaignPage from '../../pages/mail-campaign-page';
import MailCampaignsPage from '../../pages/mail-campaigns-page';
import AdPage from '../../pages/ad-page';
import AdsPage from '../../pages/ads-page';
import ExportsPage from '../../pages/exports-page';
import ParametersPage from '../../pages/parameters-page';
import CommentsPage from '../../pages/comments-page';
import CommentPage from '../../pages/comment-page';
import LoginPage from '../../pages/login-page';
import UserPage from '../../pages/user-page';
import UsersPage from '../../pages/users-page';
import { useUser } from '../../context/user-context';
import './app.css';

const App = () => {
  const user = useUser();

  return (
    <Router>
      {!user && (
        <LoginPage />
      )}

      {user && (
        <Switch>
          <Route path="/commentaires/creer">
            <CommentPage />
          </Route>
          <Route path="/commentaires/:id/modifier">
            <CommentPage />
          </Route>
          <Route path="/commentaires">
            <CommentsPage />
          </Route>
          <Route path="/dossiers/creer">
            <FolderPage />
          </Route>
          <Route path="/dossiers/:id/modifier">
            <FolderPage />
          </Route>
          <Route path="/dossiers">
            <FoldersPage />
          </Route>
          <Route path="/librairie/importer">
            <LibraryBookCreatePage />
          </Route>
          <Route path="/librairie/oeuvres/:id/modifier">
            <LibraryBookPage />
          </Route>
          <Route path="/librairie/oeuvres">
            <LibraryBooksPage />
          </Route>
          <Route path="/librairie/livres/:id/modifier">
            <LibraryBookVersionPage />
          </Route>
          <Route path="/librairie/livres">
            <LibraryBookVersionsPage />
          </Route>
          <Route path="/en-ce-moment/creer">
            <HighlightedLinkPage />
          </Route>
          <Route path="/en-ce-moment/:id/modifier">
            <HighlightedLinkPage />
          </Route>
          <Route path="/en-ce-moment">
            <HighlightedLinksPage />
          </Route>
          <Route path="/pages-statiques/creer">
            <StaticPagePage />
          </Route>
          <Route path="/pages-statiques/:id/modifier">
            <StaticPagePage />
          </Route>
          <Route path="/pages-statiques">
            <StaticPagesPage />
          </Route>
          <Route path="/images">
            <ImagesPage />
          </Route>
          <Route path="/newsletters/creer">
            <NewsletterPage />
          </Route>
          <Route path="/newsletters/:id/modifier">
            <NewsletterPage />
          </Route>
          <Route path="/newsletters">
            <NewslettersPage />
          </Route>
          <Route path="/campagnes/creer">
            <MailCampaignPage />
          </Route>
          <Route path="/campagnes/:id/modifier">
            <MailCampaignPage />
          </Route>
          <Route path="/campagnes/dupliquer/:fromId">
            <MailCampaignPage />
          </Route>
          <Route path="/campagnes">
            <MailCampaignsPage />
          </Route>
          <Route path="/publicites/creer">
            <AdPage />
          </Route>
          <Route path="/publicites/:id/modifier">
            <AdPage />
          </Route>
          <Route path="/publicites">
            <AdsPage />
          </Route>
          <Route path="/exports">
            <ExportsPage />
          </Route>
          <Route path="/parametres">
            <ParametersPage />
          </Route>
          <Route path="/articles/creer">
            <ArticlePage />
          </Route>
          <Route path="/articles/:id/modifier">
            <ArticlePage />
          </Route>
          <Route path="/articles">
            <ArticlesPage />
          </Route>
          <Route path="/categories/creer">
            <CategoryPage />
          </Route>
          <Route path="/categories/:id/modifier">
            <CategoryPage />
          </Route>
          <Route path="/categories">
            <CategoriesPage />
          </Route>
          <Route path="/auteurs/creer">
            <AuthorPage />
          </Route>
          <Route path="/auteurs/:id/modifier">
            <AuthorPage />
          </Route>
          <Route path="/auteurs">
            <AuthorsPage />
          </Route>
          <Route path="/utilisateurs/creer">
            <UserPage />
          </Route>
          <Route path="/utilisateurs/:id/modifier">
            <UserPage />
          </Route>
          <Route path="/utilisateurs">
            <UsersPage />
          </Route>
          <Route>
            <Redirect to="/articles" />
          </Route>
        </Switch>
      )}
    </Router>
  );
};

export default App;
