import React, { useEffect, useState } from 'react';
import api from '../../api';
import './category-input.css';

const CategoryInput = props => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.categories.tree({
        filter_for_parent_of: props.filterForParentOf,
      },
      data => {
        setCategories(data);
        setIsLoading(false);
      },
      err => {
        setError(`${err}`);
        setIsLoading(false);
      },
    );
  }, [props.filterForParentOf]);

  return (
    <label
      className="category-input"
      style={props.style}
    >
      {props.label && (
        <span className="category-input_label">{props.label}</span>
      )}

      {props.tips && (
        <span className="category-input_tips">{props.tips}</span>
      )}

      {error !== null && (
        <span
          className="category-input_error"
          key={error}
        >
          {error}
        </span>
      )}

      {props.errors && props.errors.map(propsError => (
        <span
          className="category-input_error"
          key={propsError}
        >
          {propsError}
        </span>
      ))}

      <select
        className={`category-input_input${props.errors.length > 0 ? ' category-input_input--error' : ''}`}
        name={props.name}
        onChange={e => props.onChange({
          target: {
            name: e.target.name,
            value: e.target.value !== '' ? parseInt(e.target.value, 10) : null,
          },
        })}
        value={props.value ? props.value : ''}
      >
        <option value="">{props.noneLabel}</option>

        {isLoading && (
          <option disabled value="">Chargement ...</option>
        )}

        {!isLoading && categories.map(category => (
          <React.Fragment key={category.id}>
            <option
              key={category.id}
              value={category.id}
            >
              {category.name}
            </option>

            {category.__children.map(subCategory => (
              <option
                key={subCategory.id}
                value={subCategory.id}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{subCategory.name}
              </option>
            ))}
          </React.Fragment>
        ))}
      </select>
    </label>
  );
};

CategoryInput.defaultProps = {
  errors: [],
  style: {},
  value: '',
  noneLabel: 'Aucune',
};

export default CategoryInput;
