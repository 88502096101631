import React from 'react';
import MailCampaignList from '../components/mail-campaign-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const MailCampaignsPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Campagnes"
        description="Créer, éditer ou supprimer des campagnes."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer une campagne"
          to="/campagnes/creer"
          theme="success"
        />
      </div>

      <MailCampaignList />
    </div>
  </Layout >
);

export default MailCampaignsPage;
