import React from 'react';
import './page-header.css';

const PageHeader = ({ title, description }) => (
  <div className="page-header">
    <p className="page-header_title">{title}</p>

    {description && (
      <p className="page-header_description">
        <span className="page-header_description-text">{description}</span>
      </p>
    )}
  </div>
);

export default PageHeader;
