import React from 'react';
import PropTypes from 'prop-types';
import './textarea.css';

const Textarea = ({ className, errors, focus, label, style, tips, ...props }) => (
  <label
    className={`textarea${className ? ` ${className}` : ''}`}
    style={style}
  >
    <span className="textarea_label">{label}</span>
    <span className="textarea_tips">{tips}</span>
    {errors && errors.map(error => (
      <span
        className="textarea_error"
        key={error}
      >
        {error}
      </span>
    ))}
    <textarea
      className={`textarea_textarea${errors.length > 0 ? ' textarea_textarea--error' : ''}`}
      autoFocus={focus}
      {...props}
    />
  </label>
);

Textarea.defaultProps = {
  errors: [],
  focus: false,
};

Textarea.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.array,
  focus: PropTypes.bool,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  tips: PropTypes.string,
};

export default Textarea;
