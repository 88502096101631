import React from 'react';
import ArticleList from '../components/article-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const ArticlesPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Articles"
        description="Créer, éditer ou supprimer des articles."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer un article"
          to="/articles/creer"
          theme="success"
        />
      </div>

      <ArticleList />
    </div>
  </Layout >
);

export default ArticlesPage;
