import React from 'react';
import CategoryList from '../components/category-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const CategoriesPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Catégories"
        description="Créer, éditer ou supprimer des catégories."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer une catégorie"
          to="/categories/creer"
          theme="success"
        />
      </div>

      <CategoryList />
    </div>
  </Layout >
);

export default CategoriesPage;
