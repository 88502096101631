import React from 'react';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import UserList from '../components/user-list';
import './page.css';

const UsersPage = () => {

  return (
    <Layout>
      <div className="page">
        <PageHeader
          title="Utilisateurs"
          description="Créer, modifier ou supprimer des utilisateurs."
        />

        <div className="page_actions">
          <LinkButton
            className="page_action-button"
            icon="add"
            label="Créer un utilisateur"
            to="/utilisateurs/creer"
            theme="success"
          />
        </div>

        <UserList />
      </div>
    </Layout>
  );
};

export default UsersPage;
