import axios from './config';

const article = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/preview-link/article/${id}`,
  ).then(
    res => successCb(res.data.link),
  ).catch(
    err => console.error('api.previewLinks.article() received error from api request:', err),
  );
};

const book = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/preview-link/book/${id}`,
  ).then(
    res => successCb(res.data.link),
  ).catch(
    err => console.error('api.previewLinks.book() received error from api request:', err),
  );
};

const libraryBook = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/preview-link/library-book/${id}`,
  ).then(
    res => successCb(res.data.link),
  ).catch(
    err => console.error('api.previewLinks.libraryBook() received error from api request:', err),
  );
};

const libraryBookVersion = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/preview-link/library-book-version/${id}`,
  ).then(
    res => successCb(res.data.link),
  ).catch(
    err => console.error('api.previewLinks.libraryBookVersion() received error from api request:', err),
  );
};

const mailCampaign = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/preview-link/mail-campaign/${id}`,
  ).then(
    res => successCb(res.data.link),
  ).catch(
    err => console.error('api.previewLinks.mailCampaign() received error from api request:', err),
  );
};

const endpoints = {
  article,
  book,
  libraryBook,
  libraryBookVersion,
  mailCampaign,
};

export default endpoints;
