import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import CategoryInput from '../category-input';
import Paginator from '../paginator';
import api from '../../api';
import './category-list.css';

const menuDisplayLabels = {
  top: 'Menu principal',
  side: 'Menu latéral',
  none: 'Aucun',
  'none-no-sub': 'Aucun, même en sous-menu',
};

const CategoryMenuDisplay = ({ menuDisplay }) => (
  <span className={`category-list_status-badge category-list_status-badge--${menuDisplay}`}>
    {menuDisplayLabels[menuDisplay]}
  </span>
);

let searchUpdateTimeout = null;

const CategoryList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const parentId = query.get('parentId') ? query.get('parentId') : null;
  const search = query.get('search') ? query.get('search') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'updatedAt';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.parentId || (parentId && !params.hasOwnProperty('parentId'))) {
      routeParams.push(`parentId=${params.parentId ? params.parentId : parentId}`);
    }

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/categories?${routeParams.join('&')}`;
  }, [parentId, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [categories, setCategories] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    setRedirect(null);
    setIsLoading(true);
    api.categories.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          parentId,
        },
      },
      data => {
        setCategories(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, parentId]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="category-list">
      <div className="category-list_nav">
        <div className="category-list_search">
          <input
            className="category-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des categories"
            value={searchInput}
          />
          <CategoryInput
            name="parentId"
            onChange={e => setRedirect(generateRouteParams({parentId: e.target.value, page: 1}))}
            value={parentId}
            noneLabel="Tous les parents"
            style={{marginRight: '8px', minWidth: '200px'}}
          />
        </div>

        <div className="category-list_pagination">
          <span className="category-list_count">Catégories {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="category-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="category-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <>
          <div className="category-list_table">
            <div className="category-list_header">
              <div
                className="category-list_header-cell category-list_header-cell--sortable category-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="category-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="category-list_header-cell category-list_header-cell--sortable category-list_cell--date"
                onClick={() => changeSort('updatedAt')}
              >
                Date de mise à jour
                {sortBy === 'updatedAt' && (
                  <i className="category-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="category-list_header-cell category-list_header-cell--sortable category-list_cell--menu-display"
                onClick={() => changeSort('menuDisplay')}
              >
                Affichage menu
                {sortBy === 'menuDisplay' && (
                  <i className="category-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="category-list_header-cell category-list_header-cell--sortable category-list_cell--priority"
                onClick={() => changeSort('priority')}
              >
                Priorité
                {sortBy === 'priority' && (
                  <i className="category-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div className="category-list_header-cell category-list_cell--name">
                <div
                  className="category-list_sub_cell category-list_header-cell--sortable category-list_sub_cell--name"
                  onClick={() => changeSort('name')}
                >
                  Nom
                  {sortBy === 'name' && (
                    <i className="category-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>
                <div
                  className="category-list_sub_cell category-list_header-cell--sortable category-list_sub_cell--parent"
                  onClick={() => changeSort('parent')}
                >
                  Catégorie parente
                  {sortBy === 'parent' && (
                    <i className="category-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>
              </div>

              <div
                className="category-list_header-cell category-list_header-cell--sortable category-list_cell--view"
                onClick={() => changeSort('nbView')}
              >
                Vues
                {sortBy === 'nbView' && (
                  <i className="category-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>
            </div>

            {categories.length > 0 && (
              <div>
                {categories.map(category => (
                  <div
                    className="category-list_row"
                    key={category.id}
                  >
                    <Link
                      className="category-list_cell category-list_cell--id"
                      title="Éditer la catégorie"
                      to={`/categories/${category.id}/modifier`}
                    >
                      {category.id}
                    </Link>

                    <Link
                      className="category-list_cell category-list_cell--date"
                      title="Éditer la catégorie"
                      to={`/categories/${category.id}/modifier`}
                    >
                      {moment(category.updatedAt).format('DD/MM/YYYY - HH:mm')}
                    </Link>

                    <Link
                      className="category-list_cell category-list_cell--menu-display"
                      title="Éditer la catégorie"
                      to={`/categories/${category.id}/modifier`}
                    >
                      <CategoryMenuDisplay menuDisplay={category.menuDisplay} />
                    </Link>

                    <Link
                      className="category-list_cell category-list_cell--priority"
                      title="Éditer la catégorie"
                      to={`/categories/${category.id}/modifier`}
                    >
                      {category.priority}
                    </Link>

                    <Link
                      className="category-list_cell"
                      title="Éditer la catégorie"
                      to={`/categories/${category.id}/modifier`}
                    >
                        <div className="category-list_sub_cell category-list_sub_cell--name">
                          {category.name}
                        </div>
                        <div className="category-list_sub_cell category-list_sub_cell--parent">
                          {category.parent ? category.parent.name : ''}
                        </div>
                    </Link>

                    <Link
                      className="category-list_cell category-list_cell--view"
                      title="Éditer la catégorie"
                      to={`/categories/${category.id}/modifier`}
                    >
                      {category.nbView}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </>
      )}

      {categories.length === 0 && !isLoading && (
        <p className="category-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default CategoryList;
