import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/fr';
import './date-input.css';

const DateInput = props => (
  <label
    className={`date-input${props.className ? ` ${props.className}` : ''}`}
    style={props.style}
  >
    <span className="date-input_label">{props.label}</span>
    <span className="date-input_tips">{props.tips}</span>
    {props.errors && props.errors.map(error => (
      <span
        className="date-input_error"
        key={error}
      >
        {error}
      </span>
    ))}
    <DateTime
      locale="fr"
      timeFormat={false}
      inputProps={{
        className: `date-input_input${props.errors.length > 0 ? ' date-input_input--error' : ''}`,
      }}
      value={moment(props.value)}
      onChange={date => props.onChange({
        target: {
          name: props.name,
          value: typeof date.format === 'function' ? date.format('YYYY-MM-DD') : date,
        }
      })}
    />
  </label>
);

DateInput.defaultProps = {
  errors: [],
};

DateInput.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.array,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  tips: PropTypes.string,
};

export default DateInput;
