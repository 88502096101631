import React from 'react';
import PropTypes from 'prop-types';
import {Editor} from '@tinymce/tinymce-react';
import EditorImagePicker from '../editor-image-picker';
import EditorDocumentPicker from '../editor-document-picker';
import './article-content-editor.css';
import { preprocess, formatContentOnChange } from '../../lib/editor-embed';

window.tinymce.PluginManager.add('actualitte', function(editor, url) {
  const insertContent = content => editor.insertContent(content);

  editor.ui.registry.addButton('actualitte_image', {
    text: 'Image',
    onAction: () => document.dispatchEvent(new CustomEvent('EditorInsertImage', {
      detail: {
        multi: false,
        onPick: insertContent
      }
    }))
  });

  editor.ui.registry.addButton('actualitte_document', {
    text: 'Document',
    onAction: () => document.dispatchEvent(new CustomEvent('EditorInsertDocument', {
      detail: {
        multi: false,
        onPick: insertContent
      }
    }))
  });

  editor.ui.registry.addButton('actualitte_pub_1', {
    text: 'Pub 1',
    onAction: () => editor.insertContent('<p>#[pub-1]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_2', {
    text: 'Pub 2',
    onAction: () => editor.insertContent('<p>#[pub-2]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_3', {
    text: 'Pub 3',
    onAction: () => editor.insertContent('<p>#[pub-3]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_4', {
    text: 'Pub 4',
    onAction: () => editor.insertContent('<p>#[pub-4]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_5', {
    text: 'Pub 5',
    onAction: () => editor.insertContent('<p>#[pub-5]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_6', {
    text: 'Pub 6',
    onAction: () => editor.insertContent('<p>#[pub-6]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_7', {
    text: 'Pub 7',
    onAction: () => editor.insertContent('<p>#[pub-7]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_8', {
    text: 'Pub 8',
    onAction: () => editor.insertContent('<p>#[pub-8]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_9', {
    text: 'Pub 9',
    onAction: () => editor.insertContent('<p>#[pub-9]</p>')
  });

  editor.ui.registry.addButton('actualitte_pub_10', {
    text: 'Pub 10',
    onAction: () => editor.insertContent('<p>#[pub-10]</p>')
  });

  // editor.ui.registry.addButton('actualitte_carousel', {
  //   text: 'Carrousel',
  //   onAction: () => document.dispatchEvent(new CustomEvent('EditorInsertImage', {
  //     detail: {
  //       multi: true,
  //       onPick: insertContent
  //     }
  //   }))
  // });

  editor.on('init', function() {
    let scriptId = editor.dom.uniqueId();

    let scriptElm = editor.dom.create('script', {
      id: scriptId,
      type: 'text/javascript',
      src: '/../../../epubjs/jszip.min.js',
    });

    editor.getDoc().getElementsByTagName('head')[0].appendChild(scriptElm);

    scriptId = editor.dom.uniqueId();

    scriptElm = editor.dom.create('script', {
      id: scriptId,
      type: 'text/javascript',
      src: '/../../../epubjs/epub.min.js',
    });

    editor.getDoc().getElementsByTagName('head')[0].appendChild(scriptElm);

    scriptId = editor.dom.uniqueId();

    scriptElm = editor.dom.create('script', {
      id: scriptId,
      type: 'text/javascript',
      src: 'https://code.jquery.com/jquery-3.5.1.slim.min.js',
    });

    editor.getDoc().getElementsByTagName('head')[0].appendChild(scriptElm);

    scriptId = editor.dom.uniqueId();

    scriptElm = editor.dom.create('script', {
      id: scriptId,
      type: 'text/javascript',
      src: 'https://platform.twitter.com/widgets.js',
      async: 'async',
    });

    editor.getDoc().getElementsByTagName('head')[0].appendChild(scriptElm);

    scriptId = editor.dom.uniqueId();

    scriptElm = editor.dom.create('script', {
      id: scriptId,
      type: 'text/javascript',
      src: 'https://connect.facebook.net/fr_FR/sdk.js',
      async: 'async',
    });

    editor.getDoc().getElementsByTagName('head')[0].appendChild(scriptElm);

    scriptId = editor.dom.uniqueId();

    scriptElm = editor.dom.create('script', {
      id: scriptId,
      type: 'text/javascript',
      src: 'https://platform.instagram.com/fr_FR/embeds.js',
      async: 'async',
    });

    editor.getDoc().getElementsByTagName('head')[0].appendChild(scriptElm);

    scriptId = editor.dom.uniqueId();

    const parsedUrl = new URL(url);
    const normalizedBase = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.port ? ':' + parsedUrl.port : ''}`;
    // console.log(normalizedBase);
    scriptElm = editor.dom.create('script', {
      id: scriptId,
      type: 'text/javascript',
      src: normalizedBase + '/insideEditor.js'
    });

    editor.getDoc().getElementsByTagName('head')[0].appendChild(scriptElm);
  });
});

const tinymceConfig = {
  branding: false,
  block_formats: 'Paragraphe=p; Titre=h2; Sous-titre=h3',
  content_css: "/inEditor.css",
  extended_valid_elements: 'iframe[src|frameborder|style|scrolling|class|width|height|name|align],script[src|async|defer|type|charset],blockquote[class]',
  fontsize_formats: '12px 16px 18px 20px 24px',
  height: 800,
  language: 'fr_FR',
  menubar: false,
  statusbar: false,
  paste_as_text: true,
  object_resizing: true,
  plugins: 'paste advlist code fullscreen image link lists table noneditable paste actualitte',
  image_advtab: true,
  toolbar: [
    'undo redo | bold italic underline strikethrough | formatselect fontsizeselect | code | fullscreen',
    'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | blockquote table link actualitte_image actualitte_document actualitte_pub_1 actualitte_pub_2 actualitte_pub_3 actualitte_pub_4 actualitte_pub_5 actualitte_pub_6 actualitte_pub_7 actualitte_pub_8 actualitte_pub_9 actualitte_pub_10'
  ],
  paste_preprocess: preprocess,
  formats: {
    alignleft: [
      {
        selector: 'figure.image',
        collapsed: false,
        classes: 'align-left',
        ceFalseOverride: true,
        preview: 'font-family font-size'
      },
      {
        selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li',
        styles: {
          textAlign: 'left'
        },
        inherit: false,
        preview: false,
        defaultBlock: 'div'
      },
      {
        selector: 'img,table',
        collapsed: false,
        styles: {
          float: 'left',
          'margin-right': '16px',
        },
        preview: 'font-family font-size'
      }
    ],
    alignright: [
      {
        selector: 'figure.image',
        collapsed: false,
        classes: 'align-right',
        ceFalseOverride: true,
        preview: 'font-family font-size'
      },
      {
        selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li',
        styles: {
          textAlign: 'right'
        },
        inherit: false,
        preview: 'font-family font-size',
        defaultBlock: 'div'
      },
      {
        selector: 'img,table',
        collapsed: false,
        styles: {
          float: 'right',
          'margin-left': '16px',
        },
        preview: 'font-family font-size'
      }
    ],
  }
};

const ArticleContentEditor = ({errors, label, name, onChange, value}) => {
  const handleChange = content => {
    formatContentOnChange(content, (newContent) => onChange({
      target: {
        name,
        value: newContent
      }
    }));
  }

  return (<div className="article-content-editor">
    <p className="article-content-editor_label">{label}</p>

    {
      errors.length > 0 && errors.map(error => (<p className="article-content-editor_error" key={error}>
        {error}
      </p>))
    }

    <Editor
      init={tinymceConfig}
      onEditorChange={handleChange}
      // onObjectSelected={(e) => console.log(e)}
      value={value}
    />

    <EditorImagePicker/>

    <EditorDocumentPicker/>
  </div>);
};

ArticleContentEditor.defaultProps = {
  errors: []
};

ArticleContentEditor.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default ArticleContentEditor;
