import axios from './config';
import { cleanContent } from '../lib/mail-editor-embed';

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/mail-campaigns`, { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/mail-campaign/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.mailCampaigns.get() received error from api request:', err),
  );
};

const post = (params, successCb, errorCb) => {
  let { mailCampaign } = params;

  if (mailCampaign.content && mailCampaign.content.trim()) {
    mailCampaign.content = cleanContent(mailCampaign.content);
  }

  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/mail-campaign`,
    mailCampaign,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const put = (params, successCb, errorCb) => {
  let { mailCampaign } = params;

  if (mailCampaign.content && mailCampaign.content.trim()) {
    mailCampaign.content = cleanContent(mailCampaign.content);
  }

  axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/mail-campaign/${params.id}`,
    mailCampaign,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const postTest = (params, successCb, errorCb) => {
  let { mailCampaign, emails } = params;

  if (mailCampaign.content && mailCampaign.content.trim()) {
    mailCampaign.content = cleanContent(mailCampaign.content);
  }

  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/mail-campaign/test`,
    { mailCampaign, emails }
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const getSendyLists = (successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/mail-campaign/sendy-lists`,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const endpoints = {
  list,
  get,
  post,
  put,
  getSendyLists,
  postTest,
};

export default endpoints;
