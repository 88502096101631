import React, { useEffect, useState } from 'react';
import Button from '../button';
import ImagePicker from '../image-picker';
import api from '../../api';
import './image-input.css';

const ImageInput = ({errors, ...props}) => {
  const [image, setImage] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (props.value) {
      api.images.get(
        props.value,
        data => setImage(data),
      );
    } else {
      setImage(null);
    }
  }, [props.value]);

  return (
    <div className="image-input">
      <span className="image-input_label">{props.label}</span>

      {props.value !== null && (
        <span className="image-input_tips">Cliquer sur l'aperçu pour changer d'image</span>
      )}

      {active && (
        <ImagePicker
          onCancel={() => setActive(false)}
          onPick={images => props.onChange({
            target: {
              name: props.name,
              value: images[0].id,
            },
          })}
        />
      )}

      <div className="image-input_input">
        {image && (
          <img
            alt=""
            className="image-input_preview"
            src={`${process.env.REACT_APP_API_HOST}${image.name}`}
            onClick={() => setActive(true)}
          />
        )}

        <Button
          label={props.value === null ? 'Choisir une image' : 'Supprimer l\'image'}
          onClick={() => {
            if (props.value === null) {
              setActive(true);
            } else {
              props.onChange({
                target: {
                  name: props.name,
                  value: null,
                },
              });
            }
          }}
          size="sm"
          theme={props.value === null ? null : 'danger'}
          type="button"
        />
      </div>
      <div>
        {errors && errors.map(error => (
          <span
            className="input_error"
            key={error}
          >
            {error}
          </span>
        ))}
      </div>
    </div>
  );
};

ImageInput.defaultProps = {
  label: 'Image',
  errors: [],
};

export default ImageInput;
