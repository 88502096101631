import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import Input from '../input';
import Checkbox from '../checkbox';
import Loader from '../loader';
import './highlighted-link-form.css';

const defaultHighlightedLink = {
  title: '',
  linkUrl: '',
  active: false,
};

const defaultErrors = {
  title: [],
  linkUrl: [],
  active: [],
};

const HighlightedLinkForm = props => {
  const [highlightedLink, setHighlightedLink] = useState(defaultHighlightedLink);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    let value = e.target.value;

    setHighlightedLink({
      ...highlightedLink,
      [e.target.name]: value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.highlightedLinks.put : api.highlightedLinks.post;
    const apiParams = props.id ? { id: props.id, highlightedLink } : { highlightedLink };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/en-ce-moment');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.highlightedLinks.get(
        props.id,
        highlightedLink => {
          let newHighlightedLink = {
            ...defaultHighlightedLink,
            ...highlightedLink,
            imageId: highlightedLink.image ? highlightedLink.image.id : null,
          };

          setHighlightedLink(newHighlightedLink);
          setIsLoading(false);
        },
      );
    } else {
      setHighlightedLink(defaultHighlightedLink);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="highlighted-link-form">
      <Form
        className="highlighted-link-form_form"
        onSubmit={handleSubmit}
      >
        <div className="highlighted-link-form_column">
          <Input
            errors={errors.title}
            label="Nom"
            name="title"
            onChange={handleChange}
            type="text"
            value={highlightedLink.title}
          />

          <Input
            errors={errors.linkUrl}
            label="Lien"
            name="linkUrl"
            onChange={handleChange}
            type="text"
            value={highlightedLink.linkUrl}
          />

          <Checkbox
            errors={errors.active}
            label="Actif"
            name="active"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={highlightedLink.active}
          />

          <Button
            className="highlighted-link-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} le lien`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

HighlightedLinkForm.propTypes = {
  id: PropTypes.number,
};

export default HighlightedLinkForm;
