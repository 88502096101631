import React, { useEffect, useState } from 'react';
import DocumentPicker from '../document-picker/document-picker';
import { editorContentFromDocuments } from '../../lib/content-generator';

const EditorDocumentPicker = () => {
  const [insertEvent, setInsertEvent] = useState(null);

  useEffect(() => {
    const handleInsertEvent = e => setInsertEvent(e);

    document.addEventListener('EditorInsertDocument', handleInsertEvent);

    return () => document.removeEventListener('EditorInsertDocument', handleInsertEvent);
  }, []);

  if (insertEvent === null) {
    return <></>;
  }

  return (
    <DocumentPicker
      multi={insertEvent.detail.multi}
      onCancel={() => setInsertEvent(null)}
      onPick={document => insertEvent.detail.onPick(editorContentFromDocuments(document))}
    />
  );
};

export default EditorDocumentPicker;
