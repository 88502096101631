import React from 'react';
import NewsletterList from '../components/newsletter-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const NewslettersPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Newsletters"
        description="Créer, éditer ou supprimer des newsletters."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer une newsletter"
          to="/newsletters/creer"
          theme="success"
        />
      </div>

      <NewsletterList />
    </div>
  </Layout >
);

export default NewslettersPage;
