import React from 'react';
import PropTypes from 'prop-types';
import './input.css';

const Input = ({ className, errors, focus, label, style, tips, onChange, ...props }) => (
  <label
    className={`input${className ? ` ${className}` : ''}`}
    style={style}
  >
    <span className="input_label">{label}</span>
    <span className="input_tips">{tips}</span>
    {errors && errors.map(error => (
      <span
        className="input_error"
        key={error}
      >
        {error}
      </span>
    ))}
    <input
      autoFocus={focus}
      className={`input_input${errors.length > 0 ? ' input_input--error' : ''}`}
      onChange={e => onChange(e)}
      {...props}
    />
  </label>
);

Input.defaultProps = {
  errors: [],
  focus: false,
};

Input.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.array,
  focus: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  tips: PropTypes.string,
};

export default Input;
