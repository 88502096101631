import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import moment from 'moment';
import Button from '../button';
import ArticleContentEditor from '../article-content-editor';
import Checkbox from '../checkbox';
import Form from '../form';
import Input from '../input';
import ImageInput from '../image-input';
import DocumentInput from '../document-input';
import Loader from '../loader';
import './library-book-form.css';
import SummaryEditor from '../summary-editor/summary-editor';
import Select from '../select';
import TagInput from '../tag-input';

const defaultBook = {
  eans: '',
  title: '',
  summary: '',
  tagId: null,
  authorRelations: [],
  versions: [],
  genre: '',
  language: '',
  coverId: null,
  textContent: '',
  audioVersionTextContent: '',
  documentContentId: null,
  status: 'draft',
  publicationDate: '',
  includedInNewsletter: false,
};

const defaultErrors = {
  summary: [],
  tagId: [],
  coverId: [],
  textContent: [],
  audioVersionTextContent: [],
  documentContentId: [],
  status: [],
  publicationDate: [],
  includedInNewsletter: [],
};

const googleAvailabilityLabels = {
  unknown: 'Inconnu',
  available: 'Disponible',
  unavailable: 'Non disponible',
};

const googleAvailability = ({ googleContentAvailability }) => (
  <span className={`library-book-form_googleContentAvailability-badge library-book-form_googleContentAvailability-badge--${googleContentAvailability}`}>
    {googleAvailabilityLabels[googleContentAvailability]}
  </span>
);

const BookForm = props => {
  const [book, setBook] = useState(defaultBook);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    setBook({
      ...book,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.libraryBooks.put : api.libraryBooks.post;
    const apiParams = props.id ? { id: props.id, book } : { book };

    if (!book.textContent) {
      book.textContent = null;
    }

    if (!book.audioVersionTextContent) {
      book.audioVersionTextContent = null;
    }

    book.publicationDate = book.publicationDate ? moment(book.publicationDate, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DD HH:mm:ss') : '';

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/librairie/oeuvres');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.libraryBooks.get(
        props.id,
        book => {
          let newBook = {
            ...defaultBook,
            ...book,
            tagId: book.tag ? book.tag.id : null,
            eans: book.eans ? book.eans.split(',').map(k => k.trim()).filter(k => k.length > 0).join(', ') : null,
            coverId: book.cover ? book.cover.id : null,
            documentContentId: book.documentContent ? book.documentContent.id : null,
            publicationDate: book.publicationDate ? moment(book.publicationDate).format('DD/MM/YYYY HH:mm') : '',
          };

          delete newBook.legacyId;
          delete newBook.legacyUrl;
          delete newBook.legacyOrigin;
          delete newBook.shortUrl;
          delete newBook.slug;
          delete newBook.cover;

          setBook(newBook);
          setIsLoading(false);
        },
      );
    } else {
      setBook(defaultBook);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="library-book-form">
      <Form
        className="library-book-form_form"
        onSubmit={handleSubmit}
      >
        <div className="library-book-form_column">
          <div className="library-book-form_infos">
            <p className="library-book-form_infos-title">Titre</p>
            <p className="library-book-form_infos-value">
              {book.title}
            </p>
            <p className="library-book-form_infos-title">EANs</p>
            <p className="library-book-form_infos-value">
              {book.eans}
            </p>
            <p className="library-book-form_infos-title">Auteurs</p>
            <p className="library-book-form_infos-value">
              {book.authorRelations ?
                book.authorRelations.map(r => r.author.name).join(', ')
                :
                <i>Non renseigné</i>
              }
            </p>
            <p className="library-book-form_infos-title">Genre</p>
            <p className="library-book-form_infos-value">
              {book.genre ?
                book.genre
                :
                <i>Non renseigné</i>
              }
            </p>
            <p className="library-book-form_infos-title">Statut Google Book</p>
            <p className="library-book-form_infos-value">
              {googleAvailability(book)}
            </p>
          </div>

          <div className="library-book-form_infos">
            <p className="library-book-form_infos-title">Versions</p>
            <p className="library-book-form_infos-value">
              <ul>
                {book.versions.map(v =>
                  <li key={v.id}>
                    {v.publisher ? v.publisher.name : <i>Editeur inconnu</i>}
                    {' - '}
                    {v.type || <i>Type inconnu</i>}
                    {' - '}
                    {v.ean}
                    {' - '}
                    {v.language}
                    {' - '}
                    {moment(v.publishedAt).format('DD/MM/YYYY') || <i>Date de publication inconnue</i>}
                    {' - '}
                    {v.nbPages ? v.nbPages + ' pages' : <i>Nb. pages inconnu</i>}
                    {' - '}
                    {v.price ? v.price.toFixed(2) + '€' : <i>Prix inconnu</i>}
                  </li>
                )}
              </ul>
            </p>
          </div>

          <ImageInput
            errors={errors.coverId}
            label="Couverture"
            name="coverId"
            onChange={handleChange}
            value={book.coverId}
          />

          <div className="library-book-form_row">
            <TagInput
              errors={errors.tagId}
              label="Option"
              name="tagId"
              onChange={handleChange}
              style={{width: '100%', marginRight: '10px'}}
              value={book.tagId}
            />

            <Select
              errors={errors.status}
              label="Statut"
              name="status"
              options={[
                {
                  label: 'En préparation',
                  value: 'draft',
                },
                {
                  label: 'Publié',
                  value: 'published',
                },
                {
                  label: 'Archivé',
                  value: 'archived',
                },
              ]}
              onChange={handleChange}
              style={{width: '100%', marginLeft: '10px'}}
              value={book.status}
            />
          </div>

          <Input
            errors={errors.publicationDate}
            label="Date de publication"
            name="publicationDate"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={book.publicationDate}
            tips="JJ/MM/AAAA HH:mm"
            type="text"
          />

          <Checkbox
            errors={errors.includedInNewsletter}
            label="Newsletter"
            name="includedInNewsletter"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={book.includedInNewsletter}
          />
        </div>

        <div className="library-book-form_column">
          <SummaryEditor
            errors={errors.summary}
            label="Résumé"
            name="summary"
            onChange={handleChange}
            value={book.summary}
          />

          <DocumentInput
            errors={errors.documentContentId}
            label="Extrait : document"
            name="documentContentId"
            onChange={handleChange}
            value={book.documentContentId}
          />

          <ArticleContentEditor
            errors={errors.textContent}
            label="Extrait : texte"
            name="textContent"
            onChange={handleChange}
            value={book.textContent}
          />

          <ArticleContentEditor
            errors={errors.audioVersionTextContent}
            label="Extrait : version audio"
            name="audioVersionTextContent"
            onChange={handleChange}
            value={book.audioVersionTextContent}
          />
        </div>

        <Button
          className="library-book-form_submit-button"
          label={`${props.id ? 'Modifier' : 'Créer'} le livre`}
          theme="success"
          type="submit"
        />

      </Form>
    </div>
  );
};

BookForm.propTypes = {
  id: PropTypes.number,
};

export default BookForm;
