import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import DateInput from '../date-input';
import ButtonLink from '../button-link';
import Loader from '../loader';
import './base-export-form.css';

const defaultBaseExport = {
  startDate: '',
  endDate: '',
};

const defaultErrors = {
  startDate: [],
  endDate: [],
};

const BaseExportForm = props => {
  const [baseExport, setBaseExport] = useState(defaultBaseExport);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const handleChange = e => {
    setBaseExport({
      ...baseExport,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    api.baseExport.post(
      baseExport,
      (data) => {
        setDownloadUrl(api.baseExport.getDownloadUrl(data.filename));
        setIsLoading(false);
      },
      err => {
        setIsLoading(false);
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
      },
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="base-export-form">
      <Form
        className="base-export-form_form"
        onSubmit={handleSubmit}
      >
        <div className="base-export-form_column">
          <DateInput
            errors={errors.startDate}
            label="Du"
            name="startDate"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={baseExport.startDate}
          />

          <DateInput
            errors={errors.endDate}
            label="Au"
            name="endDate"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={baseExport.endDate}
          />

          <div className="base-export-form_buttons-container">
            <Button
              className="base-export-form_submit-button"
              label="Générer l'export"
              theme="success"
              type="submit"
            />

            {downloadUrl &&
              <ButtonLink
                className="base-export-form_download-button"
                icon="get_app"
                label="Telecharger l'export"
                href={downloadUrl}
                theme="info"
                target="_blank"
              />}
          </div>
        </div>
      </Form>
    </div>
  );
};

BaseExportForm.propTypes = {
  id: PropTypes.number,
};

export default BaseExportForm;
