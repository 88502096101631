import React, { useState } from 'react';
import DocumentUploader from '../document-uploader';
import PageHeader from '../page-header';
import './document-picker.css';

const DocumentPicker = props => {
  const [tab, setTab] = useState(() => props.multi ? 'list' : 'upload');

  return (
    <div className="document-picker">
      <div className="document-picker_overlay" onClick={() => props.onCancel()}></div>
      <div className="document-picker_wrapper">
        <div className="document-picker_container">
          <div className="document-picker_header">
            <PageHeader
              title="Insérer un document"
            />
          </div>

          <div className="document-picker_content">
            {tab === 'upload' && (
              <DocumentUploader onUpload={document => {
                if (props.multi) {
                  setTab('list');
                } else {
                  props.onPick([document]);
                  props.onCancel();
                }
              }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentPicker.defaultProps = {
  multi: false,
};

export default DocumentPicker;
