import React, { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import LibraryBookVersionForm from '../components/library-book-version-form';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import ButtonLink from '../components/button-link';
import PageHeader from '../components/page-header';
import api from '../api';
import './page.css';
import './library-book-version-page.css';

const LibraryBookVersionPage = () => {
  const { id } = useParams();

  const [previewLink, setPreviewLink] = useState(null);

  useEffect(() => {
    if (id) {
      api.previewLinks.libraryBookVersion(
        id,
        link => {
          setPreviewLink(link);
        },
      );
    }
  }, [id]);

  return (
    <Layout>
      <div className="page">
        <PageHeader
          title={`${id ? 'Modifier' : 'Créer'} un livre`}
        />

        <div className="page_actions">
          <LinkButton
            className="page_action-button"
            icon="arrow_back"
            label="Retour"
            to="/librairie/livres"
            theme="danger"
          />

          {id && previewLink &&
            <ButtonLink
              className="page_action-button"
              icon="remove_red_eye"
              label="Voir en ligne"
              href={`${process.env.REACT_APP_API_HOST}${previewLink}`}
              theme="info"
              target="_blank"
            />
          }

        </div>

        <LibraryBookVersionForm id={parseInt(id, 10)} />
      </div>
    </Layout>
  );
};

export default LibraryBookVersionPage;
