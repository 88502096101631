import React, { useState } from 'react';
import Button from '../button';
import Input from '../input';
import Loader from '../loader';
import api from '../../api';
import './document-uploader.css';

const defaultDocument = {
  documentFile: '',
  documentFileFile: null,
};

const defaultErrors = {
  global: [],
  documentFile: [],
};

const DocumentUploader = props => {
  const [document, setDocument] = useState(defaultDocument);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(false);

  const validateFile = () => {
    if (document.documentFile === '' || document.documentFileFile === null) {
      setErrors({
        ...errors,
        documentFile: ['Veuillez sélectionner un document à envoyer'],
      });

      return false;
    }

    if (document.documentFileFile.size > (1024 * 1024 * process.env.REACT_APP_UPLOAD_MAX_FILESIZE)) {
      setErrors({
        ...errors,
        documentFile: ['Fichier trop volumineux'],
      });

      return false;
    }

    return true;
  }

  const submit = () => {
    const formData = new FormData();

    formData.append('documentFile', document.documentFileFile);

    if (validateFile()) {
      setIsLoading(true);
      api.documents.post(
        formData,
        data => props.onUpload(data),
        err => {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));

          if (err.response) {
            if (Array.isArray(err.response.data.violations)) {
              err.response.data.violations.map(error => {
                if (Array.isArray(newErrors[error.propertyPath])) {
                  return newErrors[error.propertyPath].push(error.title);
                }

                return newErrors['global'].push(error.title);
              });
            } else {
              newErrors['global'].push('Une erreur s\'est produite.');
              console.error('<DocumentUploader>: Unable to parse API error response data\n', err.response.data);
            }

            setErrors(newErrors);
          } else {
            newErrors['global'].push('Une erreur s\'est produite.');
            console.error('<DocumentUploader>: Unknown API error\n', err);
          }

          setDocument({
            ...document,
            documentFile: '',
            documentFileFile: null,
          });
          setIsLoading(false);
        },
      );
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="document-uploader">
      {errors.global.length > 0 && errors.global.map(error => (
        <span className="document-uploader_error" key={error}>{error}</span>
      ))}

      <Input
        accept="application/pdf, application/epub+zip"
        errors={errors.documentFile}
        label="Choisir un fichier"
        name="documentFile"
        onChange={e => {
          setDocument({
            ...document,
            documentFile: e.target.value,
            documentFileFile: e.target.files[0],
          });
          setErrors({
            ...errors,
            documentFile: [],
          });
        }}
        tips={`Taille max. du document : ${process.env.REACT_APP_UPLOAD_MAX_FILESIZE} Mo`}
        type="file"
        value={document.documentFile}
      />

      <br />

      <Button
        label="Envoyer"
        theme="success"
        type="button"
        onClick={submit}
      />
    </div>
  );
};

export default DocumentUploader;
