import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Select from '../select';
import Paginator from '../paginator';
import api from '../../api';
import './highlighted-link-list.css';

let searchUpdateTimeout = null;

const HighlightedLinkActive = ({ active }) => (
  <span className={`highlighted-link-list_active-badge highlighted-link-list_active-badge--${active ? 'active' : 'inactive'}`}>
    {active ? 'Actif' : 'Inactif'}
  </span>
);

const HighlightedLinkList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const active = query.get('active') ? query.get('active') : null;
  const search = query.get('search') ? query.get('search') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'updatedAt';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.active || (active && !params.hasOwnProperty('active'))) {
      routeParams.push(`active=${params.active ? params.active : active}`);
    }

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/en-ce-moment?${routeParams.join('&')}`;
  }, [active, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [highlightedLinks, setHighlightedLinks] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    const activeValues = {
      '0': false,
      '1': true,
      'all': ''
    };

    setRedirect(null);
    setIsLoading(true);
    api.highlightedLinks.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          active: activeValues[active],
        },
      },
      data => {
        setHighlightedLinks(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, active]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="highlighted-link-list">
      <div className="highlighted-link-list_nav">
        <div className="highlighted-link-list_search">
          <input
            className="highlighted-link-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des liens"
            value={searchInput}
          />

          <Select
            name="active"
            options={[
              {
                label: 'Tous les statuts',
                value: 'all',
              },
              {
                label: 'Actif',
                value: '1',
              },
              {
                label: 'Inactif',
                value: '0',
              },
            ]}
            onChange={e => setRedirect(generateRouteParams({active: e.target.value, page: 1}))}
            label=""
            value={active || ''}
          />
        </div>

        <div className="highlighted-link-list_pagination">
          <span className="highlighted-link-list_count">Liens {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="highlighted-link-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="highlighted-link-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <div>
          <div className="highlighted-link-list_table">
            <div className="highlighted-link-list_header">
              <div
                className="highlighted-link-list_header-cell highlighted-link-list_header-cell--sortable highlighted-link-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="highlighted-link-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="highlighted-link-list_header-cell highlighted-link-list_header-cell--sortable highlighted-link-list_cell--date"
                onClick={() => changeSort('updatedAt')}
              >
                Date
                {sortBy === 'updatedAt' && (
                  <i className="highlighted-link-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="highlighted-link-list_header-cell highlighted-link-list_header-cell--sortable highlighted-link-list_cell--name"
                onClick={() => changeSort('title')}
              >
                Nom
                {sortBy === 'title' && (
                  <i className="highlighted-link-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="highlighted-link-list_header-cell highlighted-link-list_header-cell--sortable highlighted-link-list_cell--name"
                onClick={() => changeSort('linkUrl')}
              >
                Lien
                {sortBy === 'linkUrl' && (
                  <i className="highlighted-link-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="highlighted-link-list_header-cell highlighted-link-list_header-cell--sortable highlighted-link-list_cell--active"
                onClick={() => changeSort('active')}
              >
                Statut
                {sortBy === 'active' && (
                  <i className="highlighted-link-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>
            </div>

            {highlightedLinks.length > 0 && (
              <div>
                {highlightedLinks.map(highlightedLink => (
                  <div
                    className="highlighted-link-list_row"
                    key={highlightedLink.id}
                  >
                    <Link
                      className="highlighted-link-list_cell highlighted-link-list_cell--id"
                      title="Éditer la lien"
                      to={`/en-ce-moment/${highlightedLink.id}/modifier`}
                    >
                      {highlightedLink.id}
                    </Link>

                    <Link
                      className="highlighted-link-list_cell highlighted-link-list_cell--date"
                      title="Éditer la lien"
                      to={`/en-ce-moment/${highlightedLink.id}/modifier`}
                    >
                      {moment(highlightedLink.updatedAt).format('DD/MM/YYYY - HH:mm')}
                    </Link>

                    <Link
                      className="highlighted-link-list_cell highlighted-link-list_cell--name"
                      title="Éditer la lien"
                      to={`/en-ce-moment/${highlightedLink.id}/modifier`}
                    >
                      {highlightedLink.title}
                    </Link>

                    <Link
                      className="highlighted-link-list_cell highlighted-link-list_cell--name"
                      title="Éditer la lien"
                      to={`/en-ce-moment/${highlightedLink.id}/modifier`}
                    >
                      {highlightedLink.linkUrl}
                    </Link>

                    <div className="highlighted-link-list_cell highlighted-link-list_cell--active">
                      <HighlightedLinkActive active={highlightedLink.active} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </div>
      )}

      {highlightedLinks.length === 0 && !isLoading && (
        <p className="highlighted-link-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default HighlightedLinkList;
