import axios from './config';

const post = (params, successCb, errorCb) => {
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/export/base`,
    params,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const getDownloadUrl = (filename) => {
  return `${process.env.REACT_APP_API_BASE_URL}/export/download/${filename}`;
}

const endpoints = {
  post,
  getDownloadUrl,
};

export default endpoints;