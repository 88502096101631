/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Editor from 'dnd-email-editor';

import { formatContentOnChange } from '../../lib/mail-editor-embed';
import './email-content-editor.css';
import ImagePicker from '../image-picker';

const MailContentEditor = ({ errors, label, htmlFieldName, structuredFieldName, onChange, structuredFieldValue }) => {

  const ref = useRef(null);

  const [imagePicking, setImagePicking] = useState(false);
  const [imagePickingEventDetail, setImagePickingEventDetail] = useState(null);

  const clickOnImgSrc = (e) => {
    setImagePickingEventDetail(e.detail);
    setImagePicking(true);
  };

  const handleChange = (e) => {
    if (!ref.current) {
      return;
    }

    const htmlContent = ref.current.getHtml();
    const structuredContent = ref.current.getJson();

    formatContentOnChange(htmlContent, (newHtmlContent) => {
      onChange({
        targets: [{
          name: htmlFieldName,
          value: newHtmlContent,
        }, {
          name: structuredFieldName,
          value: structuredContent
        }]
      });
    });
  };

  useEffect(() => {
    document.addEventListener("dnde-state-update", handleChange);

    return () => {
      document.removeEventListener("dnde-state-update", handleChange);
    };

  }, [onChange]);

  useEffect(() => {
    if (ref.current && structuredFieldValue) {
      // console.log("Structured", structuredFieldValue);
      if (structuredFieldValue.indexOf('"tagName":"mjml"') === -1) {
        structuredFieldValue = '';
        return;
      }

      ref.current.loadJson(structuredFieldValue);
    }

    document.addEventListener("dnde-img-src-focus", clickOnImgSrc);

    return () => {
      document.removeEventListener("dnde-img-src-focus", clickOnImgSrc);
    };
  }, []);

  const handlePickedImage = (image) => {
    imagePickingEventDetail.handleChange({ currentTarget: { value: `${process.env.REACT_APP_API_HOST}${image.name}` } });

    setImagePicking(false);
    setImagePickingEventDetail(null);
  };

  // const performUndoAction = () => {
  //   if (ref.current) {
  //     ref.current.undoActionCallback();

  //     // to check if undo is possible
  //     console.log('is undo empty: ', ref.current.isUndoEmpty());
  //   }
  // };

  // const performRedoAction = () => {
  //   if (ref.current) {
  //     ref.current.redoActionCallback();

  //     // to check if redo is possible
  //     console.log('is redo empty: ', ref.current.isRedoEmpty());
  //   }
  // };

  return (
    <div className="email-content-editor">
      <p className="email-content-editor_label">{label}</p>

      {
        errors.length > 0 && errors.map(error => (<p className="email-content-editor_error" key={error}>
          {error}
        </p>))
      }

      <div className="email-content-editor-wrapper">
        <div style={{ width: '100%', height: '800px' }}>
          <Editor ref={ref} showUndoRedo={false} />
        </div>
      </div>

      {imagePicking && (
        <ImagePicker
          onCancel={() => {
            setImagePicking(false);
            setImagePickingEventDetail(null);
          }}
          onPick={(images) => {
            handlePickedImage(images[0]);
          }}
        />
      )}
    </div>
  );
}

MailContentEditor.defaultProps = {
  errors: []
};

MailContentEditor.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  htmlFieldName: PropTypes.string.isRequired,
  structuredFieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  structuredFieldValue: PropTypes.string,
};

export default MailContentEditor;
