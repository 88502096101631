import axios from './config';
import { cleanContent } from '../lib/editor-embed';

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/static-pages`, { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/static-page/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.static-page.get() received error from api request:', err),
  );
};

const post = (params, successCb, errorCb) => {
  let { staticPage } = params;

  if (staticPage.content && staticPage.content.trim()) {
    staticPage.content = cleanContent(staticPage.content);
  }

  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/static-page`,
    params.staticPage,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const put = (params, successCb, errorCb) => {
  let { staticPage } = params;

  if (staticPage.content && staticPage.content.trim()) {
    staticPage.content = cleanContent(staticPage.content);
  }

  axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/static-page/${params.id}`,
    staticPage,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const endpoints = {
  list,
  get,
  post,
  put,
};

export default endpoints;
