import { AllHtmlEntities } from 'html-entities';
import api from '../api';

const entities = new AllHtmlEntities();

export const preprocess = (plugin, args) => {
  if (args.content.includes('&lt;iframe') && args.content.includes('&lt;/iframe&gt;') && args.content.includes('src=')) {
    const newContent = entities.decode(args.content);
    args.content = `<p></p><div class="mceNonEditable"><div style="text-align: center;">${newContent}</div></div><p></p>`;
  } else if (args.content.includes('class=&quot;instagram-media&quot;')) {
    const newContent = entities.decode(args.content);
    // console.log(newContent);
    newContent.replace(/<scipt.*<\/script>/g, '');
    args.content = `<p></p><div class="mceNonEditable"><div style="text-align: center;">${newContent}</div></div><p></p>`;
  } else if (args.content.includes('class=&quot;fb-post&quot;')) {
    const newContent = entities.decode(args.content);
    args.content = `<p></p><div class="mceNonEditable"><div style="text-align: center;">${newContent}</div></div><p></p>`;
  } else if (args.content.includes('data-flickr-embed=')) {
    const newContent = entities.decode(args.content);
    args.content = `<p></p><div><div style="text-align: center;">${newContent}</div></div><p></p>`;
  } else if (args.content.includes('class=&quot;twitter-tweet&quot;')) {
    const newContent = entities.decode(args.content).replace(/<br \/>/g, '');
    const matches = newContent.match(/(https:\/\/twitter.com\/[^?"]+\/(?:status|timeline)\/\d+)/);
    if (matches && matches[1]) {
      api.embed.twitter({
        url: matches[1]
      }, (data) => {
        args.target.editorManager.execCommand('mceInsertRawHTML', false, `<p></p><div class="mceNonEditable"><div>${data.html}</div></div><p></p>`);
      }, () => {
        args.target.editorManager.execCommand('mceInsertRawHTML', false, `<p></p><div class="mceNonEditable"><div>${newContent}</div></div><p></p>`);
      })
    }
    args.content = '';
  }
}

export const formatContentOnChange = (content, cb) => {
  cb(content);
}

export const cleanContent = (content) => {
  if (!content) {
    return content;
  }

  let tmp = document.createElement('div');
  tmp.innerHTML = content;
  tmp.setAttribute('id', 'tmp_elem_article_content');
  document.getElementsByTagName('body')[0].appendChild(tmp);
  const elems = document.querySelectorAll('#tmp_elem_article_content .epub-container');
  for (var i = 0; i < elems.length; i++) {
    elems[i].parentNode.removeChild(elems[i]);
  }
  let newContent = '' + tmp.innerHTML;
  tmp.parentNode.removeChild(tmp);
  newContent = newContent.replace(/<p><iframe src="https:\/\/platform\.twitter\.com\/widgets\/widget_iframe.+<\/p>/g, '');

  // console.log(content);
  // console.log(newContent);

  return newContent;
}
