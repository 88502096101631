import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Paginator from '../paginator';
import api from '../../api';
import './author-list.css';

let searchUpdateTimeout = null;

const ActiveToggle = ({ author }) => {
  const [state, setState] = useState(author.active);
  const [isLoading, setIsLoading] = useState(false);

  const generateClassname = () => {
    let className = 'author-list_active-toggle';

    if (state) {
      className += ' author-list_active-toggle--active';
    }

    if (isLoading) {
      className += ' author-list_active-toggle--loading';
    }

    return className;
  };

  const toggle = () => {
    setIsLoading(true);
    api.authors.put(
      {
        id: author.id,
        author: {
          active: !state,
        },
      },
      () => {
        setState(!state);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <span
      className={generateClassname()}
      onClick={e => {
        e.preventDefault();

        if (!isLoading) {
          toggle();
        }
      }}
    >
      <i className="author-list_active-toggle-icon material-icons">{state ? 'toggle_on' : 'toggle_off'}</i>
    </span>
  );
};

const AuthorList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const search = query.get('search') ? query.get('search') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'updatedAt';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/auteurs?${routeParams.join('&')}`;
  }, [search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    setRedirect(null);
    setIsLoading(true);
    api.authors.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
      },
      data => {
        setAuthors(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="author-list">
      <div className="author-list_nav">
        <div className="author-list_search">
          <input
            className="author-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des auteurs"
            value={searchInput}
          />
        </div>

        <div className="author-list_pagination">
          <span className="author-list_count">Auteurs {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="author-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="author-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <>
          <div className="author-list_table">
            <div className="author-list_header">
              <div
                className="author-list_header-cell author-list_header-cell--sortable author-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="author-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="author-list_header-cell author-list_header-cell--sortable author-list_cell--date"
                onClick={() => changeSort('updatedAt')}
              >
                Date de mise à jour
                {sortBy === 'updatedAt' && (
                  <i className="author-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div className="author-list_header-cell author-list_cell--name">
                <div
                  className="author-list_sub_cell author-list_header-cell--sortable author-list_sub_cell--name"
                  onClick={() => changeSort('name')}
                >
                  Nom
                  {sortBy === 'name' && (
                    <i className="author-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>

                <div
                  className="author-list_sub_cell author-list_header-cell--sortable author-list_sub_cell--name"
                  onClick={() => changeSort('email')}
                >
                  Email
                  {sortBy === 'email' && (
                    <i className="author-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>
              </div>

              <div
                className="author-list_header-cell author-list_header-cell--sortable author-list_cell--view"
                onClick={() => changeSort('nbView')}
              >
                Vues
                {sortBy === 'nbView' && (
                  <i className="author-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="author-list_header-cell author-list_header-cell--sortable author-list_cell--active"
                onClick={() => changeSort('active')}
              >
                Actif
                {sortBy === 'active' && (
                  <i className="author-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

            </div>

            {authors.length > 0 && (
              <div>
                {authors.map(author => (
                  <div
                    className="author-list_row"
                    key={author.id}
                  >
                    <Link
                      className="author-list_cell author-list_cell--id"
                      title="Éditer l'auteur"
                      to={`/auteurs/${author.id}/modifier`}
                    >
                      {author.id}
                    </Link>

                    <Link
                      className="author-list_cell author-list_cell--date"
                      title="Éditer l'auteur"
                      to={`/auteurs/${author.id}/modifier`}
                    >
                      {moment(author.updatedAt).format('DD/MM/YYYY - HH:mm')}
                    </Link>

                    <Link
                      className="author-list_cell"
                      title="Éditer l'auteur"
                      to={`/auteurs/${author.id}/modifier`}
                    >
                        <div className="author-list_sub_cell author-list_sub_cell--name">
                          {author.name}
                        </div>
                        <div className="author-list_sub_cell author-list_sub_cell--parent">
                          {author.email}
                        </div>
                    </Link>

                    <Link
                      className="author-list_cell author-list_cell--view"
                      title="Éditer l'auteur"
                      to={`/auteurs/${author.id}/modifier`}
                    >
                      {author.nbView}
                    </Link>

                    <div className="author-list_cell author-list_cell--active">
                      <ActiveToggle author={author} />
                    </div>

                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </>
      )}

      {authors.length === 0 && !isLoading && (
        <p className="author-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default AuthorList;
