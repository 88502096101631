import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react'
import './title-editor.css';

const tinymceConfig = {
  branding: false,
  height: 100,
  language: 'fr_FR',
  menubar: false,
  paste_as_text: true,
  plugins: '',
  statusbar: false,
  toolbar: [
    'italic',
  ],
};

const TitleEditor = ({ errors, label, name, onChange, value }) => {
  const handleChange = content => onChange({
    target: {
      name,
      value: content,
    },
  });

  return (
    <div className="title-editor">
      <p className="title-editor_label">{label}</p>

      {errors.length > 0 && errors.map(error => (
        <p
          className="title-editor_error"
          key={error}
        >
          {error}
        </p>
      ))}

      <Editor
        init={tinymceConfig}
        onEditorChange={handleChange}
        value={value}
      />
    </div>
  );
};

TitleEditor.defaultProps = {
  errors: [],
};

TitleEditor.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default TitleEditor;
