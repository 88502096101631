import axios from './config';
// import moment from 'moment';
import { cleanContent } from '../lib/editor-embed';

const cast = (book) => {
  return book;
}

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/library-book-versions`, { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const get = (id, successCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/library-book-version/${id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => console.error('api.libraryBook.get() received error from api request:', err),
  );
};

const put = (params, successCb, errorCb) => {
  let { book } = params;

  if (book.textContent && book.textContent.trim()) {
    book.textContent = cleanContent(book.textContent);
  }

  if (book.audioVersionTextContent && book.audioVersionTextContent.trim()) {
    book.audioVersionTextContent = cleanContent(book.audioVersionTextContent);
  }

  axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/library-book-version/${params.id}`,
    cast(book),
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const updateData = (params, successCb, errorCb) => {
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/library-book-version/update-data/${params.id}`,
  ).then(
    res => successCb(res.data),
  ).catch(
    errorCb,
  );
};

const endpoints = {
  list,
  get,
  put,
  updateData,
};

export default endpoints;