import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Select from '../select';
import CategoryInput from '../category-input';
import Loader from '../loader';
import Paginator from '../paginator';
import api from '../../api';
import './article-list.css';

const statusesLabels = {
  draft: 'En préparation',
  review: 'En relecture',
  published: 'Publié',
  archived: 'Archivé',
};

const positioningLabels = {
  none: 'Normal',
  main: 'À la une',
  secondary: 'Une secondaire',
  highlighted: 'Mis en avant',
  boxed: 'Petites unes',
};

const CategoryBadge = props => (
  <span className={`article-list_category-badge${props.category ? '' : ' article-list_category-badge--null'}`}>
    {props.category ? props.category.name : 'Aucune'}
  </span>
);

const NewsletterToggle = ({ article }) => {
  const [state, setState] = useState(article.includedInNewsletter);
  const [isLoading, setIsLoading] = useState(false);

  const generateClassname = () => {
    let className = 'article-list_newsletter-toggle';

    if (state) {
      className += ' article-list_newsletter-toggle--active';
    }

    if (isLoading) {
      className += ' article-list_newsletter-toggle--loading';
    }

    return className;
  };

  const toggle = () => {
    setIsLoading(true);
    api.articles.put(
      {
        id: article.id,
        article: {
          includedInNewsletter: !state,
        },
      },
      () => {
        setState(!state);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <span
      className={generateClassname()}
      onClick={e => {
        e.preventDefault();

        if (!isLoading) {
          toggle();
        }
      }}
    >
      <i className="article-list_newsletter-toggle-icon material-icons">{state ? 'toggle_on' : 'toggle_off'}</i>
    </span>
  );
};

const PositioningSelector = ({ article, refreshList }) => {
  const [isLoading, setIsLoading] = useState(false);

  const generateClassname = () => {
    let className = 'article-list_positioning-selector';

    if (isLoading) {
      className += ' article-list_positioning-selector--loading';
    }

    return className;
  };

  const select = (positioning) => {
    setIsLoading(true);
    api.articles.put(
      {
        id: article.id,
        article: {
          positioning,
        },
      },
      () => {
        refreshList();
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <select
      className={generateClassname()}
      onChange={e => {
        select(e.target.value);
      }}
      value={article.positioning}
    >
      <option value="none">{positioningLabels.none}</option>
      <option value="main">{positioningLabels.main}</option>
      <option value="secondary">{positioningLabels.secondary}</option>
      <option value="highlighted">{positioningLabels.highlighted}</option>
    </select>
  );
};

const GlobalPositioningSelector = ({ article, refreshList }) => {
  const [isLoading, setIsLoading] = useState(false);

  const generateClassname = () => {
    let className = 'article-list_positioning-selector';

    if (isLoading) {
      className += ' article-list_positioning-selector--loading';
    }

    return className;
  };

  const select = (globalPositioning) => {
    setIsLoading(true);
    api.articles.put(
      {
        id: article.id,
        article: {
          globalPositioning,
        },
      },
      () => {
        refreshList();
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <select
      className={generateClassname()}
      onChange={e => {
        select(e.target.value);
      }}
      value={article.globalPositioning}
    >
      <option value="none">{positioningLabels.none}</option>
      <option value="main">{positioningLabels.main}</option>
      <option value="secondary">{positioningLabels.secondary}</option>
      <option value="highlighted">{positioningLabels.highlighted}</option>
      <option value="boxed">{positioningLabels.boxed}</option>
    </select>
  );
};

const ArticleStatus = ({ status }) => (
  <span className={`article-list_status-badge article-list_status-badge--${status}`}>
    {statusesLabels[status]}
  </span>
);

let searchUpdateTimeout = null;

const ArticleList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const categoryId = query.get('categoryId') ? query.get('categoryId') : null;
  const status = query.get('status') ? query.get('status') : '';
  const search = query.get('search') ? query.get('search') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'redactionDate';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.categoryId || (categoryId && !params.hasOwnProperty('categoryId'))) {
      routeParams.push(`categoryId=${params.categoryId ? params.categoryId : categoryId}`);
    }

    if (params.status || (status && !params.hasOwnProperty('status'))) {
      routeParams.push(`status=${params.hasOwnProperty('status') ? params.status : status}`);
    }

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/articles?${routeParams.join('&')}`;
  }, [categoryId, status, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [articles, setArticles] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState(search);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const refreshList = useCallback(() => {
    setRedirect(null);
    setIsLoading(true);
    api.articles.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          categoryId,
          status,
        },
      },
      data => {
        setArticles(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, categoryId, status]);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  if (redirect !== null) {
    return <Redirect push to={redirect} />;
  }

  return (
    <div className="article-list">
      <div className="article-list_nav">
        <div className="article-list_search">
          <input
            className="article-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des articles"
            value={searchInput}
          />
          <CategoryInput
            name="categoryId"
            onChange={e => setRedirect(generateRouteParams({categoryId: e.target.value, page: 1}))}
            value={categoryId}
            noneLabel="Toutes les catégories"
            style={{marginRight: '8px', minWidth: '200px'}}
          />
          <Select
            name="status"
            options={[
              {
                label: 'Tous les statuts',
                value: '',
              },
              {
                label: 'En préparation',
                value: 'draft',
              },
              {
                label: 'En relecture',
                value: 'review',
              },
              {
                label: 'Publié',
                value: 'published',
              },
              {
                label: 'Archivé',
                value: 'archived',
              },
            ]}
            onChange={e => setRedirect(generateRouteParams({status: e.target.value, page: 1}))}
            label=""
            value={status}
          />
        </div>

        <div className="article-list_pagination">
          <span className="article-list_count">Articles {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="article-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="article-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <>
          <div className="article-list_content">
            <div className="article-list_table">
              <div className="article-list_header">
                <div
                  className="article-list_header-cell article-list_header-cell--sortable article-list_cell--id"
                  onClick={() => changeSort('id')}
                >
                  ID
                  {sortBy === 'id' && (
                    <i className="article-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>

                <div
                  className="article-list_header-cell article-list_header-cell--sortable article-list_cell--date"
                  onClick={() => changeSort('redactionDate')}
                >
                  Date de publication
                  {sortBy === 'redactionDate' && (
                    <i className="article-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>

                <div
                  className="article-list_header-cell article-list_header-cell--sortable article-list_cell--title"
                  onClick={() => changeSort('slug')}
                >
                  Titre
                  {sortBy === 'slug' && (
                    <i className="article-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>

                <div className="article-list_header-cell article-list_cell--category">
                  Catégorie
                </div>

                <div className="article-list_header-cell article-list_header-cell--sortable article-list_cell--livetwwet"
                  onClick={() => changeSort('liveTweetActive')}
                >
                  Livetweet
                  {sortBy === 'liveTweetActive' && (
                    <i className="article-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>

                <div className="article-list_header-cell article-list_header-cell--sortable article-list_cell--views"
                  onClick={() => changeSort('nbView')}
                >
                  Vues
                  {sortBy === 'nbView' && (
                    <i className="article-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                  )}
                </div>

                <div className="article-list_header-cell article-list_cell--newsletter">
                  Newsletter
                </div>

                <div className="article-list_header-cell article-list_cell--positioning">
                  Affichage Une
                </div>

                <div className="article-list_header-cell article-list_cell--positioning">
                  Affichage Catég.
                </div>

                <div className="article-list_header-cell article-list_cell--status">
                  Statut
                </div>
              </div>

              {articles.length > 0 && (
                <div>
                  {articles.map(article => (
                    <div
                      className="article-list_row"
                      key={article.id}
                    >
                      <Link
                        className="article-list_cell article-list_cell--id"
                        title="Éditer l'article"
                        to={`/articles/${article.id}/modifier`}
                      >
                        {article.id}
                      </Link>

                      <Link
                        className="article-list_cell article-list_cell--date"
                        title="Éditer l'article"
                        to={`/articles/${article.id}/modifier`}
                      >
                        {moment(article.redactionDate).format('DD/MM/YYYY - HH:mm')}
                      </Link>

                      <Link
                        className="article-list_cell article-list_cell--title"
                        title="Éditer l'article"
                        to={`/articles/${article.id}/modifier`}
                        dangerouslySetInnerHTML={{__html: `<div>${article.titleHtml}</div>`}}
                      >
                      </Link>

                      <Link
                        className="article-list_cell article-list_cell--category"
                        title="Éditer l'article"
                        to={`/articles/${article.id}/modifier`}
                      >
                        <CategoryBadge category={article.category} />
                      </Link>

                      <Link
                        className="article-list_cell article-list_cell--livetwwet"
                        title="Éditer l'article"
                        to={`/articles/${article.id}/modifier`}
                      >
                        {article.liveTweetActive ? 'Oui' : 'Non'}
                      </Link>

                      <Link
                        className="article-list_cell article-list_cell--views"
                        title="Nombre de vues"
                        to={`/articles/${article.id}/modifier`}
                      >
                        {article.nbView}
                      </Link>

                      <div className="article-list_cell article-list_cell--newsletter">
                        <NewsletterToggle article={article} />
                      </div>

                      <div className="article-list_cell article-list_cell--positioning">
                        <GlobalPositioningSelector article={article} refreshList={refreshList} />
                      </div>

                      <div className="article-list_cell article-list_cell--positioning">
                        <PositioningSelector article={article} refreshList={refreshList}/>
                      </div>

                      <div className="article-list_cell article-list_cell--status">
                        <ArticleStatus status={article.status} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </>
      )}

      {articles.length === 0 && !isLoading && (
        <p className="article-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default ArticleList;
