import React from 'react';
import StaticPageList from '../components/static-page-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const StaticPagesPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Pages statiques"
        description="Créer, éditer ou supprimer des pages statiques."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer une page statique"
          to="/pages-statiques/creer"
          theme="success"
        />
      </div>

      <StaticPageList />
    </div>
  </Layout >
);

export default StaticPagesPage;
