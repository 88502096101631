import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Select from '../select';
import Paginator from '../paginator';
import api from '../../api';
import './ad-list.css';

let searchUpdateTimeout = null;

const AdActive = ({ active }) => (
  <span className={`ad-list_active-badge ad-list_active-badge--${active ? 'active' : 'inactive'}`}>
    {active ? 'Active' : 'Inactive'}
  </span>
);

const typeLabels = {
  html: 'HTML / JS',
  image: 'Image',
};


const AdType = ({ type }) => (
  <span className={`ad-list_active-badge ad-list_type-badge--${type}`}>
    {typeLabels[type]}
  </span>
);

const positionLabels = {
  'ga-1': 'Desktop - Grand angle 1',
  'ga-2': 'Desktop - Grand angle 2',
  'ban': 'Desktop - Bannière',
  'inner-ban': 'Desktop - Bannière commentaires',
  'in-content-ban-1': 'Desktop - Bannière in-content 1',
  'in-content-ban-2': 'Desktop - Bannière in-content 2',
  'in-content-ban-3': 'Desktop - Bannière in-content 3',
  'in-content-ban-4': 'Desktop - Bannière in-content 4',
  'in-content-ban-5': 'Desktop - Bannière in-content 5',
  'in-content-ban-6': 'Desktop - Bannière in-content 6',
  'in-content-ban-7': 'Desktop - Bannière in-content 7',
  'in-content-ban-8': 'Desktop - Bannière in-content 8',
  'in-content-ban-9': 'Desktop - Bannière in-content 9',
  'in-content-ban-10': 'Desktop - Bannière in-content 10',
  'mb-1': 'Mobile - Pavé 1',
  // 'mb-2': 'Mobile - Pavé 2',
  'mb-3': 'Mobile - Pavé commentaires',
  'mb-in-content-1': 'Mobile - In-content 1',
  'mb-in-content-2': 'Mobile - In-content 2',
  'mb-in-content-3': 'Mobile - In-content 3',
  'mb-in-content-4': 'Mobile - In-content 4',
  'mb-in-content-5': 'Mobile - In-content 5',
  'mb-in-content-6': 'Mobile - In-content 6',
  'mb-in-content-7': 'Mobile - In-content 7',
  'mb-in-content-8': 'Mobile - In-content 8',
  'mb-in-content-9': 'Mobile - In-content 9',
  'mb-in-content-10': 'Mobile - In-content 10',
  'nl': 'Newsletter - Bannière',
};


const AdPosition = ({ position }) => (
  <span className={`ad-list_active-badge ad-list_position-badge--${position}`}>
    {positionLabels[position]}
  </span>
);

const AdList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const active = query.get('active') ? query.get('active') : null;
  const search = query.get('search') ? query.get('search') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'updatedAt';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.active || (active && !params.hasOwnProperty('active'))) {
      routeParams.push(`active=${params.active ? params.active : active}`);
    }

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/publicites?${routeParams.join('&')}`;
  }, [active, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [ads, setAds] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    const activeValues = {
      '0': false,
      '1': true,
      'all': ''
    };

    setRedirect(null);
    setIsLoading(true);
    api.ads.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          active: activeValues[active],
        },
      },
      data => {
        setAds(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, active]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="ad-list">
      <div className="ad-list_nav">
        <div className="ad-list_search">
          <input
            className="ad-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des publicités"
            value={searchInput}
          />

          <Select
            name="active"
            options={[
              {
                label: 'Tous les statuts',
                value: 'all',
              },
              {
                label: 'Active',
                value: '1',
              },
              {
                label: 'Inactive',
                value: '0',
              },
            ]}
            onChange={e => setRedirect(generateRouteParams({active: e.target.value, page: 1}))}
            label=""
            value={active || ''}
          />
        </div>

        <div className="ad-list_pagination">
          <span className="ad-list_count">Publicités {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="ad-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="ad-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <div>
          <div className="ad-list_table">
            <div className="ad-list_header">
              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--date"
                onClick={() => changeSort('updatedAt')}
              >
                Date
                {sortBy === 'updatedAt' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--type"
                onClick={() => changeSort('type')}
              >
                Type
                {sortBy === 'type' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--position"
                onClick={() => changeSort('position')}
              >
                Position
                {sortBy === 'position' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--name"
                onClick={() => changeSort('campaignName')}
              >
                Nom
                {sortBy === 'campaignName' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--ownername"
                onClick={() => changeSort('ownerName')}
              >
                Diffuseur
                {sortBy === 'ownerName' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--views"
                onClick={() => changeSort('nbView')}
              >
                Vues
                {sortBy === 'nbView' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--clicks"
                onClick={() => changeSort('nbClick')}
              >
                Clicks
                {sortBy === 'nbClick' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="ad-list_header-cell ad-list_header-cell--sortable ad-list_cell--active"
                onClick={() => changeSort('active')}
              >
                Statut
                {sortBy === 'active' && (
                  <i className="ad-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>
            </div>

            {ads.length > 0 && (
              <div>
                {ads.map(ad => (
                  <div
                    className="ad-list_row"
                    key={ad.id}
                  >
                    <Link
                      className="ad-list_cell ad-list_cell--id"
                      title="Éditer la publicité"
                      to={`/publicites/${ad.id}/modifier`}
                    >
                      {ad.id}
                    </Link>

                    <Link
                      className="ad-list_cell ad-list_cell--date"
                      title="Éditer la publicité"
                      to={`/publicites/${ad.id}/modifier`}
                    >
                      {moment(ad.updatedAt).format('DD/MM/YYYY - HH:mm')}
                    </Link>

                    <div className="ad-list_cell ad-list_cell--type">
                      <AdType type={ad.type} />
                    </div>

                    <div className="ad-list_cell ad-list_cell--position">
                      <AdPosition position={ad.position} />
                    </div>

                    <Link
                      className="ad-list_cell ad-list_cell--name"
                      title="Éditer la publicité"
                      to={`/publicites/${ad.id}/modifier`}
                    >
                      {ad.campaignName}
                    </Link>

                    <Link
                      className="ad-list_cell ad-list_cell--ownername"
                      title="Éditer la publicité"
                      to={`/publicites/${ad.id}/modifier`}
                    >
                      {ad.ownerName}
                    </Link>

                    <Link
                      className="ad-list_cell ad-list_cell--views"
                      title="Éditer la publicité"
                      to={`/publicites/${ad.id}/modifier`}
                    >
                      {ad.nbView}
                    </Link>

                    <Link
                      className="ad-list_cell ad-list_cell--clicks"
                      title="Éditer la publicité"
                      to={`/publicites/${ad.id}/modifier`}
                    >
                      {ad.nbClick}
                    </Link>

                    <div className="ad-list_cell ad-list_cell--active">
                      <AdActive active={ad.active} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </div>
      )}

      {ads.length === 0 && !isLoading && (
        <p className="ad-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default AdList;
