import React, { useEffect, useState } from 'react';
import Loader from '../loader';
import api from '../../api';
import './image-list.css';

const ImageList = props => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selection, setSelection] = useState([]);

  const addToSelection = image => {
    for (let selected of selection) {
      if (selected.id === image.id) {
        return false;
      }
    }

    setSelection([
      ...selection,
      image,
    ]);

    return true;
  };

  useEffect(() => {
    setIsLoading(true);
    api.images.list(
      data => {
        setImages(data.items);
        setIsLoading(false);
      },
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={`image-list${props.multi ? ' image-list--multi' : ''}`}>
      <div className="image-list_images">
        {images.length > 0 && images.map(image => (
          <div
            className="image-list_image"
            key={image.id}
            onClick={() => {
              if (props.multi) {
                addToSelection(image);
              } else {
                props.onSelect([image]);
              }
            }}
          >
            <img
              alt=""
              className="image-list_image-image"
              src={`${process.env.REACT_APP_API_HOST}${image.name}`}
            />
          </div>
        ))}

        {!images.length > 0 && (
          <p>Aucun résultat</p>
        )}
      </div>

      {props.multi && (
        <div className="image-list_selection">
          <button
            onClick={() => {
              props.onSelect(selection);
            }}
            type="button"
          >
            Sélectionner
          </button>

          {selection.map(image => (
            <img
              alt=""
              className="image-list_selection-image"
              key={image.id}
              src={`${process.env.REACT_APP_API_HOST}${image.name}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

ImageList.defaultProps = {
  multi: false,
};

export default ImageList;
