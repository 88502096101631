import React from 'react';
import PropTypes from 'prop-types';
import './select.css';

const Select = props => {
  return (
    <label
      className="select"
      style={props.style}
    >
      <span className="select_label">{props.label}</span>

      {props.tips && (
        <span className="select_tips">{props.tips}</span>
      )}

      {props.errors.length > 0 && props.errors.map((error, idx) => (
        <span key={idx} className="select_error">{error}</span>
      ))}

      <select
        className="select_input"
        name={props.name}
        onChange={props.onChange}
        value={props.value}
      >
        {props.options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </label>
  );
};

Select.defaultProps = {
  errors: [],
  style: {},
};

Select.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  style: PropTypes.object,
  tips: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default Select;
