import React, { useEffect, useState } from 'react';
import api from '../../api';
import Loader from '../loader';
import Checkbox from '../checkbox';
import ButtonLink from '../button-link';

const SendyListsInput = props => {
  const [lists, setLists] = useState([]);
  const [listsMainLink, setListsMainLink] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.mailCampaigns.getSendyLists(
      data => {
        setLists(data.items);
        setListsMainLink(data.mainLink);
        setIsLoading(false);

        const selection = [];
        for (const lId of props.value) {
          if (lId) {
            selection.push(lId);
          }
        }

        setSelected(selection);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, []);

  const handleChange = (c) => {
    // console.log(c.id, c.value);

    const newSelection = [];

    for (const l of selected) {
      if (c.id !== l || c.value) {
        newSelection.push(l);
      }
    }

    if (c.value && newSelection.indexOf(c.id) === -1) {
      newSelection.push(c.id)
    }

    // console.log(newSelection);

    setSelected(newSelection);

    props.onChange({
      target: {
        name: props.name,
        value: [...newSelection],
      },
    });
  }

  const isSelected = (listId) => {
    return selected.indexOf(listId) !== -1;
  }

  return (
    <label
      className="category-input"
      style={props.style}
    >
      {props.label && (
        <span className="category-input_label">{props.label}</span>
      )}

      {props.tips && (
        <span className="category-input_tips">{props.tips}</span>
      )}

      {error !== null && (
        <span
          className="category-input_error"
          key={error}
        >
          {error}
        </span>
      )}

      {props.errors && props.errors.map(propsError => (
        <span
          className="category-input_error"
          key={propsError}
        >
          {propsError}
        </span>
      ))}

      {isLoading && (
        <Loader />
      )}

      {!isLoading && (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {lists.map(l =>
            <div key={`list_${l.id}`} style={{ marginRight: '15px' }}>
              <Checkbox
                label={l.name}
                labelLink={l.link}
                name={`list_${l.id}`}
                onChange={(e) => handleChange({
                  id: l.id,
                  value: e.target.value,
                })}
                style={{ 
                  marginRight: '10px', 
                  backgroundColor: '#eeeeee',
                  padding: '2px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                value={isSelected(l.id)}
              />
            </div>
          )}
        </div>
      )}

      {!isLoading && (
        <div style={{marginTop: '20px'}}>
          <ButtonLink
            className="mail-campaign-from_lists-button"
            size="sm"
            label="Voir les listes sur Sendy"
            href={listsMainLink}
            theme="info"
            target="_blank"
          ></ButtonLink>
        </div>
      )}
    </label>
  );
};

SendyListsInput.defaultProps = {
  errors: [],
  style: {},
  value: [],
};

export default SendyListsInput;
