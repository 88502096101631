import axios from './config';

const list = (params, successCb, errorCb) => {
  axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/tags`,
    { params },
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const endpoints = {
  list,
};

export default endpoints;
