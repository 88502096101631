import React from 'react';
import PropTypes from 'prop-types';
import { rolesFromLabel, rolesLabels, rolesToLabel } from '../../lib/roles';
import './roles-input.css';

const RolesInput = ({ className, errors, label, onChange, style, tips, value, ...props }) => {
  return (
    <label
      className={`roles-input${className ? ` ${className}` : ''}`}
      style={style}
    >
      <span className="roles-input_label">{label}</span>
      <span className="roles-input_tips">{tips}</span>

      {
        errors.length > 0 && errors.map(error => (<p className="roles-input_error" key={error}>
          {error}
        </p>))
      }

      <select
        className="roles-input_input"
        onChange={e => onChange({
          target: {
            name: e.target.name,
            value: rolesFromLabel(e.target.value),
          },
        })}
        value={rolesToLabel(value)}
        {...props}
      >
        {rolesLabels.map(rolesLabel => (
          <option
            key={rolesLabel.label}
            value={rolesLabel.label}
          >
            {rolesLabel.label}
          </option>
        ))}
      </select>
    </label>
  );
};

RolesInput.defaultProps = {
  errors: [],
  value: [],
};

RolesInput.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.array,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  tips: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RolesInput;
