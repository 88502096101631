import React from 'react';
import LoginForm from '../components/login-form';
import './login-page.css';

const LoginPage = () => (
  <div className="login-page">
    <LoginForm />
  </div>
);

export default LoginPage;
