import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import Input from '../input';
import TextArea from '../textarea';
import Select from '../select';
import Loader from '../loader';
import './comment-form.css';

const defaultComment = {
  name: '',
  email: '',
  content: '',
  status: 'waiting_validation',
  articleId: null,
  bookId: null,
};

const defaultErrors = {
  name: [],
  email: [],
  content: [],
  status: [],
  articleId: [],
  bookId: [],
};

const CommentForm = props => {
  const [comment, setComment] = useState(defaultComment);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    let value = e.target.value;
    if (e.target.name === 'email') {
      value = value || null;
    }
    setComment({
      ...comment,
      [e.target.name]: value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.comments.put : api.comments.post;
    const apiParams = props.id ? { id: props.id, comment } : { comment };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/commentaires');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.comments.get(
        props.id,
        comment => {
          let newComment = {
            ...defaultComment,
            ...comment,
          };

          delete newComment.article;

          setComment(newComment);
          setIsLoading(false);
        },
      );
    } else {
      setComment(defaultComment);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="comment-form">
      <Form
        className="comment-form_form"
        onSubmit={handleSubmit}
      >
        <div className="comment-form_column">
          <Input
            errors={errors.name}
            label="Nom"
            name="name"
            onChange={handleChange}
            type="text"
            value={comment.name}
          />

          <Input
            errors={errors.email}
            label="Email"
            name="email"
            onChange={handleChange}
            type="email"
            value={comment.email}
          />

          <TextArea
            errors={errors.content}
            label="Message"
            name="content"
            onChange={handleChange}
            value={comment.content}
          />

          <Select
            errors={errors.status}
            label="Statut"
            name="status"
            options={[
              {
                label: 'En attente de validation',
                value: 'waiting_validation',
              },
              {
                label: 'Accepté',
                value: 'published',
              },
              {
                label: 'Refusé avec mention',
                value: 'refused_mention',
              },
              {
                label: 'Refusé',
                value: 'refused',
              },
            ]}
            onChange={handleChange}
            style={{width: '100%'}}
            value={comment.status}
          />

          <Button
            className="comment-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} le commentaire`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

CommentForm.propTypes = {
  id: PropTypes.number,
};

export default CommentForm;
