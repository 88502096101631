import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Select from '../select';
import Paginator from '../paginator';
import api from '../../api';
import './comment-list.css';

let searchUpdateTimeout = null;

const statusesLabels = {
  waiting_validation: 'En attente de validation',
  published: 'Publié',
  refused_mention: 'Refusé avec mention',
  refused: 'Refusé',
};

const CommentStatus = ({ status }) => (
  <span className={`comment-list_status-badge comment-list_status-badge--${status}`}>
    {statusesLabels[status]}
  </span>
);

const CommentList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const status = query.get('status') ? query.get('status') : '';
  const search = query.get('search') ? query.get('search') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'updatedAt';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.status || (status && !params.hasOwnProperty('status'))) {
      routeParams.push(`status=${params.status ? params.status : status}`);
    }

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/commentaires?${routeParams.join('&')}`;
  }, [status, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [comments, setComments] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    setRedirect(null);
    setIsLoading(true);
    api.comments.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          status,
        },
      },
      data => {
        setComments(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, status]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="comment-list">
      <div className="comment-list_nav">
        <div className="comment-list_search">
          <input
            className="comment-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des commentaires"
            value={searchInput}
          />

          <Select
            name="status"
            options={[
              {
                label: 'Tous les statuts',
                value: '',
              },
              {
                label: 'En attente de validation',
                value: 'waiting_validation',
              },
              {
                label: 'Refusé',
                value: 'refused',
              },
              {
                label: 'Publié',
                value: 'published',
              },
            ]}
            onChange={e => setRedirect(generateRouteParams({status: e.target.value, page: 1}))}
            label=""
            value={status}
          />
        </div>

        <div className="comment-list_pagination">
          <span className="comment-list_count">Commentaires {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="comment-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="comment-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <>
          <div className="comment-list_table">
            <div className="comment-list_header">
              <div
                className="comment-list_header-cell comment-list_header-cell--sortable comment-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="comment-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="comment-list_header-cell comment-list_header-cell--sortable comment-list_cell--date"
                onClick={() => changeSort('createdAt')}
              >
                Date
                {sortBy === 'createdAt' && (
                  <i className="comment-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="comment-list_header-cell comment-list_header-cell--sortable comment-list_cell--name"
                onClick={() => changeSort('name')}
              >
                Nom
                {sortBy === 'name' && (
                  <i className="comment-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="comment-list_header-cell comment-list_header-cell--sortable comment-list_cell--article"
                onClick={() => changeSort('article')}
              >
                Article
                {sortBy === 'article' && (
                  <i className="comment-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div className="comment-list_header-cell comment-list_cell--status">
                Statut
              </div>
            </div>

            {comments.length > 0 && (
              <div>
                {comments.map(comment => (
                  <div
                    className="comment-list_row"
                    key={comment.id}
                  >
                    <Link
                      className="comment-list_cell comment-list_cell--id"
                      title="Éditer le commentaire"
                      to={`/commentaires/${comment.id}/modifier`}
                    >
                      {comment.id}
                    </Link>

                    <Link
                      className="comment-list_cell comment-list_cell--date"
                      title="Éditer le commentaire"
                      to={`/commentaires/${comment.id}/modifier`}
                    >
                      {moment(comment.createdAt).format('DD/MM/YYYY - HH:mm')}
                    </Link>

                    <Link
                      className="comment-list_cell comment-list_cell--name"
                      title="Éditer le commentaire"
                      to={`/commentaires/${comment.id}/modifier`}
                    >
                      {comment.name}
                    </Link>

                    <Link
                      className="comment-list_cell comment-list_cell--article"
                      title="Éditer le commentaire"
                      to={`/commentaires/${comment.id}/modifier`}
                      dangerouslySetInnerHTML={{__html: comment.article ? comment.article.titleHtml : `<i>${(comment.book || comment.libraryBook || comment.libraryBookVersion).title}</i>`}}
                    >
                    </Link>

                    <div className="comment-list_cell comment-list_cell--status">
                      <CommentStatus status={comment.status} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
        </>
      )}

      {comments.length === 0 && !isLoading && (
        <p className="comment-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default CommentList;
