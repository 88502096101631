import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.css';

const Checkbox = props => {
  return (
    <label
      className="checkbox"
      style={props.style}
    >
      {props.errors && props.errors.map(error => (
        <span
          className="input_error"
          key={error}
        >
          {error}
        </span>
      ))}

      {props.label && (
        <span className="checkbox_label">
          {!props.labelLink && props.label}
          {props.labelLink && <a href={props.labelLink}>{props.label}</a>}
        </span>
      )}

      <input
        checked={props.value}
        className="checkbox_input"
        name={props.name}
        onChange={e => props.onChange({
          target: {
            name: e.target.name,
            value: e.target.checked,
          }
        })}
        type="checkbox"
      />

      <span className={`checkbox_toggle${props.value ? ' checkbox_toggle--active' : ''}`}>
        <i className="checkbox_toggle-icon material-icons">{props.value ? 'toggle_on' : 'toggle_off'}</i>
      </span>
    </label>
  );
};

Checkbox.defaultProps = {
  style: {},
};

Checkbox.propTypes = {
  label: PropTypes.string,
  labelLink: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  value: PropTypes.bool.isRequired,
};

export default Checkbox;
