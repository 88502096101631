import React from 'react';
import HighlightedLinkList from '../components/highlighted-link-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const HighlightedLinksPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="En ce moment"
        description="Créer, éditer ou supprimer des liens."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer un lien"
          to="/en-ce-moment/creer"
          theme="success"
        />
      </div>

      <HighlightedLinkList />
    </div>
  </Layout >
);

export default HighlightedLinksPage;
