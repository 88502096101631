import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Form from '../form';
import Button from '../button';
import TextArea from '../textarea';
import Loader from '../loader';
import './library-book-create.css';

const defaultBookCreate = {
  eans: '',
};

const defaultErrors = {
  eans: [],
};

const BookCreate = () => {
  const [bookCreate, setBookCreate] = useState(defaultBookCreate);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    setBookCreate({
      ...bookCreate,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod =  api.libraryBooks.importEans;
    const apiParams = { eans: bookCreate.eans };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/librairie/livres');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="library-book-create">
      <Form
        className="library-book-create_form"
        onSubmit={handleSubmit}
      >
          <TextArea
            errors={errors.eans}
            label="Liste d'EANs"
            name="eans"
            onChange={handleChange}
            style={{width: '100%'}}
            value={bookCreate.eans}
            tips="Un EAN par ligne, ou séparés par des virgules"
            rows="20"
          />

          <Button
            className="library-book-create_submit-button"
            label="Lancer l'import des livres"
            theme="success"
            type="submit"
          />

      </Form>
    </div>
  );
};

export default BookCreate;
