export const carouselContentFromImages = images => {
  let content = '<p></p><div class="article_carousel mceNonEditable">';

  for (let image of images) {
    content += `<img class="article_carousel-image" src="${process.env.REACT_APP_API_HOST}${image.name}">`;
  }

  content += '</div><p></p>';

  return content;
};

export const editorContentFromImages = images => {
  if (!Array.isArray(images)) {
    console.error(`'images' is not an array`);
    return '';
  }

  if (images.length < 1) {
    return '';
  }

  if (images.length === 1) {
    // return `<p></p><img class="article_image" src="${process.env.REACT_APP_API_HOST}${images[0].name}"><p></p>`;
    if (!images[0].caption) {
      return `<p></p><img class="article_image" src="${process.env.REACT_APP_API_HOST}${images[0].name}" alt="${images[0].name}"><p></p>`;
    }
    return `<p></p><figure class="image"><img class="article_image" src="${process.env.REACT_APP_API_HOST}${images[0].name}" alt="${images[0].caption}"><figcaption>${images[0].caption}</figcaption></figure><p></p>`;
  }

  return carouselContentFromImages(images);
};

export const editorContentFromDocuments = documents => {
  if (!Array.isArray(documents)) {
    console.error(`'documents' is not an array`);
    return '';
  }

  if (documents.length < 1) {
    return '';
  }

  if (documents.length === 1 && documents[0].name.endsWith('pdf')) {
    return `<p></p><div class="mceNonEditable article_pdf"><object style="width:100%; height:850px;" type="application/pdf" data="${process.env.REACT_APP_API_HOST}${documents[0].name}" width="100%" height="600px"></object></div><p></p>`;
  }

  if (documents.length === 1 && documents[0].name.endsWith('epub')) {
    return `<p></p><div class="mceNonEditable article_epub"><div id="epub-${documents[0].id}" class="wrapper-epub-container" data-name="${documents[0].id}" data-url="${process.env.REACT_APP_API_HOST}/api/v1/document/file/${documents[0].id}.epub"></div></div><p></p>`;
  }
};
