import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Paginator from '../paginator';
import api from '../../api';
import './mail-campaign-list.css';

const statusesLabels = {
  draft: 'En préparation',
  prepared: 'Prêt',
  sent: 'Envoyé',
  error: 'Erreur',
};

const MailCampaignStatus = ({ status }) => (
  <span className={`mail-campaign-list_status-badge mail-campaign-list_status-badge--${status}`}>
    {statusesLabels[status]}
  </span>
);

let searchUpdateTimeout = null;

const MailCampaignList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const search = query.get('search') ? query.get('search') : '';
  const status = query.get('status') ? query.get('status') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'updatedAt';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.status || (status && !params.hasOwnProperty('status'))) {
      routeParams.push(`status=${params.hasOwnProperty('status') ? params.status : status}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/campagnes?${routeParams.join('&')}`;
  }, [status, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [mailCampaigns, setMailCampaigns] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lists, setLists] = useState([]);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({ sortBy: by, sortDir: dir, page: 1 }));
  };

  const resolveListsNames = listIds => listIds.map(lId => {
    const list = lists.find((l) => `${l.id}` === `${lId}`);
    if (list) {
      return list.name;
    }
    return null;
  }).filter(f => f !== null);

  const duplicate = (fromId) => {
    if (fromId) {
      setIsLoading(true);
      setRedirect(`/campagnes/dupliquer/${fromId}`);
    }
  };

  useEffect(() => {
    api.mailCampaigns.getSendyLists(
      data => {
        setLists(data.items);
      },
    );
  }, []);

  useEffect(() => {
    setRedirect(null);
    setIsLoading(true);
    api.mailCampaigns.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          status,
        },
      },
      data => {
        setMailCampaigns(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, status]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="mail-campaign-list">
      <div className="mail-campaign-list_nav">
        <div className="mail-campaign-list_search">
          <input
            className="mail-campaign-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({ search: val, page: 1 }));
              }, 600);
            }}
            placeholder="Rechercher des campagnes"
            value={searchInput}
          />
        </div>

        <div className="mail-campaign-list_pagination">
          <span className="mail-campaign-list_count">Campagnes {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="mail-campaign-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({ itemsPerPage: e.target.value, page: 1 }))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="mail-campaign-list_error">{'Une erreur s\'est produite, essayez de réactualiser la page.'}</p>}

      {!isLoading && !error && (
        <div>
          <div className="mail-campaign-list_table">
            <div className="mail-campaign-list_header">
              <div
                className="mail-campaign-list_header-cell mail-campaign-list_header-cell--sortable mail-campaign-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="mail-campaign-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="mail-campaign-list_header-cell mail-campaign-list_header-cell--sortable mail-campaign-list_cell--date"
                onClick={() => changeSort('sendDate')}
              >
                {'Date d\'envoi'}
                {sortBy === 'sendDate' && (
                  <i className="mail-campaign-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="mail-campaign-list_header-cell mail-campaign-list_header-cell--sortable mail-campaign-list_cell--title"
                onClick={() => changeSort('slug')}
              >
                Titre
                {sortBy === 'slug' && (
                  <i className="mail-campaign-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="mail-campaign-list_header-cell mail-campaign-list_header-cell--sortable mail-campaign-list_cell--lists"
                onClick={() => changeSort('lists')}
              >
                Listes
                {sortBy === 'lists' && (
                  <i className="mail-campaign-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div className="mail-campaign-list_header-cell mail-campaign-list_cell--status">
                Statut
              </div>

              <div className="mail-campaign-list_header-cell mail-campaign-list_cell--actions">
                Actions
              </div>
            </div>

            {mailCampaigns.length > 0 && (
              <div>
                {mailCampaigns.map(mailCampaign => (
                  <div
                    className="mail-campaign-list_row"
                    key={mailCampaign.id}
                  >
                    <Link
                      className="mail-campaign-list_cell mail-campaign-list_cell--id"
                      title="Éditer la campagne"
                      to={`/campagnes/${mailCampaign.id}/modifier`}
                    >
                      {mailCampaign.id}
                    </Link>

                    <Link
                      className="mail-campaign-list_cell mail-campaign-list_cell--date"
                      title="Éditer la campagne"
                      to={`/campagnes/${mailCampaign.id}/modifier`}
                    >
                      {`${moment(mailCampaign.sendDate).format('DD/MM/YYYY')} à ${moment(mailCampaign.sendDate).format('HH:mm')}`}
                    </Link>

                    <Link
                      className="mail-campaign-list_cell mail-campaign-list_cell--title"
                      title="Éditer la campagne"
                      to={`/campagnes/${mailCampaign.id}/modifier`}
                    >
                      {mailCampaign.title}
                    </Link>

                    <Link
                      className="mail-campaign-list_cell mail-campaign-list_cell--lists"
                      title="Listes"
                      to={`/campagnes/${mailCampaign.id}/modifier`}
                    >
                      {resolveListsNames(mailCampaign.listIds).join(', ')}
                    </Link>

                    <div className="mail-campaign-list_cell mail-campaign-list_cell--status">
                      <MailCampaignStatus status={mailCampaign.status} />
                    </div>

                    <div className="mail-campaign-list_cell mail-campaign-list_cell--actions">
                      <i className="list-action_icon material-icons" onClick={() => duplicate(mailCampaign.id)}>content_copy</i>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({ page: '[page]' })}
          />
        </div>
      )}

      {mailCampaigns.length === 0 && !isLoading && (
        <p className="mail-campaign-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default MailCampaignList;
