import React, { useEffect, useState } from 'react';
import api from '../../api';
// import './category-input.css';

const AuthorInput = props => {
  const [authors, setAuthors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.authors.list(
      {},
      data => {
        setAuthors(data.items.filter(a => (!props.onlyActive || a.id === parseInt(props.value) || a.active)));
        setIsLoading(false);
      },
      err => {
        setError(`${err}`);
        setIsLoading(false);
      },
    );
  }, [props.onlyActive, props.value]);

  return (
    <label
      className="category-input"
      style={props.style}
    >
      {props.label && (
        <span className="category-input_label">{props.label}</span>
      )}

      {props.tips && (
        <span className="category-input_tips">{props.tips}</span>
      )}

      {error !== null && (
        <span
          className="category-input_error"
          key={error}
        >
          {error}
        </span>
      )}

      {props.errors && props.errors.map(propsError => (
        <span
          className="category-input_error"
          key={propsError}
        >
          {propsError}
        </span>
      ))}

      <select
        className={`category-input_input${props.errors.length > 0 ? ' category-input_input--error' : ''}`}
        name={props.name}
        onChange={e => props.onChange({
          target: {
            name: e.target.name,
            value: e.target.value !== '' ? parseInt(e.target.value, 10) : null,
          },
        })}
        value={props.value ? props.value : ''}
      >
        <option value="">Aucun</option>

        {isLoading && (
          <option disabled value="">Chargement ...</option>
        )}

        {!isLoading && authors.length === 0 && (
          <option disabled value="">Aucun auteur trouvé ...</option>
        )}

        {!isLoading && authors.length > 0 && authors.map(authors => (
            <option
              key={authors.id}
              value={authors.id}
            >
              {authors.name}
            </option>
        ))}
      </select>
    </label>
  );
};

AuthorInput.defaultProps = {
  errors: [],
  style: {},
  value: '',
  onlyActive: false,
};

export default AuthorInput;
