import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import Button from '../button';
import Form from '../form';
import Checkbox from '../checkbox';
import Input from '../input';
import Loader from '../loader';
import RolesInput from '../roles-input';
import './user-form.css';

const defaultUser = {
  name: '',
  email: '',
  twitterProfileUrl: '',
  instagramProfileUrl: '',
  facebookProfileUrl: '',
  description: '',
  active: true,
};

const defaultErrors = {
  username: [],
  email: [],
  firstname: [],
  lastname: [],
  roles: [],
  plainPassword: [],
  active: [],
};

const UserForm = props => {
  const [user, setUser] = useState(defaultUser);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.users.put : api.users.post;
    const apiParams = props.id ? { id: props.id, user } : { user };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/utilisateurs');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.users.get(
        props.id,
        user => {
          let newUser = {
            ...defaultUser,
            ...user,
          };

          delete newUser.children;
          setUser(newUser);
          setIsLoading(false);
        },
      );
    } else {
      setUser(defaultUser);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="user-form">
      <Form
        className="user-form_form"
        onSubmit={handleSubmit}
      >
        <div className="user-form_column">
          <Input
            errors={errors.username}
            label="Nom d'utilisateur"
            name="username"
            onChange={handleChange}
            type="text"
            value={user.username}
          />

          <Checkbox
            errors={errors.active}
            label="Actif"
            name="active"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={user.active}
          />

          <Input
            errors={errors.email}
            label="Email"
            name="email"
            onChange={handleChange}
            type="email"
            value={user.email}
          />

          <Input
            errors={errors.firstname}
            label="Prénom"
            name="firstname"
            onChange={handleChange}
            type="text"
            value={user.firstname}
          />

          <Input
            errors={errors.lastname}
            label="Nom"
            name="lastname"
            onChange={handleChange}
            type="text"
            value={user.lastname}
          />

          <RolesInput
            errors={errors.roles}
            label="Rôle"
            name="roles"
            onChange={handleChange}
            value={user.roles}
          />

          <Input
            errors={errors.plainPassword}
            label="Mot de passe"
            tips={props.id ? 'Laisser vide pour conserver le mot de passe actuel.' : ''}
            name="plainPassword"
            onChange={handleChange}
            type="password"
            value={user.plainPassword}
          />

          <Button
            className="user-form_submit-button"
            label={`${props.id ? 'Modifier' : 'Créer'} l'utilisateur`}
            theme="success"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

UserForm.propTypes = {
  id: PropTypes.number,
};

export default UserForm;
