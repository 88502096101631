import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react'
import './summary-editor.css';

const tinymceConfig = {
  content_style: `
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap');

    * {
        margin: 0;
        padding: 0;
    }

    html {
      /* background-color: gray; */
    }

    body {
      color: black;
      background-color: white;
      font-size: 18px;
      line-height: 28px;
      /* text-align: justify; */
      text-align: left;
      padding-right: 60px;
      font-family: 'Poppins', sans-serif;
      max-width: 944px;
      margin: 0 auto;
      padding: 28px 20px;
    }

    body a {
      color: inherit;
    }
  `,
  branding: false,
  height: 256,
  language: 'fr_FR',
  menubar: false,
  paste_as_text: true,
  plugins: 'paste link',
  statusbar: false,
  toolbar: [
    'undo redo | bold italic underline strikethrough link',
  ],
};

const SummaryEditor = ({ errors, label, name, onChange, value }) => {
  const handleChange = content => onChange({
    target: {
      name,
      value: content,
    },
  });

  return (
    <div className="summary-editor">
      <p className="summary-editor_label">{label}</p>

      {errors.length > 0 && errors.map(error => (
        <p
          className="summary-editor_error"
          key={error}
        >
          {error}
        </p>
      ))}

      <Editor
        init={tinymceConfig}
        onEditorChange={handleChange}
        value={value}
      />
    </div>
  );
};

SummaryEditor.defaultProps = {
  errors: [],
};

SummaryEditor.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SummaryEditor;
