import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import api from '../../api';
import ArticleContentEditor from '../article-content-editor';
import Button from '../button';
import Form from '../form';
import ImageInput from '../image-input';
import Input from '../input';
import Loader from '../loader';
import './folder-form.css';
import SummaryEditor from '../summary-editor/summary-editor';
import Select from '../select';
import Textarea from '../textarea';
import Checkbox from '../checkbox';

const defaultFolder = {
  title: '',
  summary: '',
  content: '',
  keywords: '',
  eans: '',
  status: 'draft',
  mainImageId: null,
  includedInNewsletter: false,
};

const defaultErrors = {
  title: [],
  summary: [],
  content: [],
  keywords: [],
  eans: [],
  status: [],
  mainImageId: [],
  includedInNewsletter: [],
};

const FolderForm = props => {
  const [folder, setFolder] = useState(defaultFolder);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [redirect, setRedirect] = useState('');

  const handleChange = e => {
    setFolder({
      ...folder,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.folders.put : api.folders.post;
    const apiParams = props.id ? { id: props.id, folder } : { folder };

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      () => {
        setRedirect('/dossiers');
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      api.folders.get(
        props.id,
        folder => {
          let newFolder = {
            ...defaultFolder,
            ...folder,
            keywords: folder.keywords ? folder.keywords.split(',').map(k => k.trim()).filter(k => k.length > 0).join(', ') : null,
            eans: folder.eans ? folder.eans.replace(/\n/g, ',').split(',').map(k => k.trim()).filter(k => k.length > 0).join('\n') : null,
            mainImageId: folder.mainImage ? folder.mainImage.id : null,
          };

          delete newFolder.category;
          delete newFolder.legacyId;
          delete newFolder.legacyUrl;
          delete newFolder.secondaryCategory;
          delete newFolder.shortUrl;
          delete newFolder.slug;
          delete newFolder.author;
          delete newFolder.tag;
          delete newFolder.mainImage;
          setFolder(newFolder);
          setIsLoading(false);
        },
      );
    } else {
      setFolder(defaultFolder);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="folder-form">
      <Form
        className="folder-form_form"
        onSubmit={handleSubmit}
      >
        <div className="folder-form_column">

          <ImageInput
            errors={errors.mainImageId}
            label="Image Principale"
            name="mainImageId"
            onChange={handleChange}
            value={folder.mainImageId}
          />

          <Input
            errors={errors.keywords}
            label="Mots-clés"
            tips="Séparés par une virgule"
            name="keywords"
            onChange={handleChange}
            type="text"
            value={folder.keywords}
          />

          <Textarea
            errors={errors.eans}
            label="Liste d'EANs"
            name="eans"
            onChange={handleChange}
            style={{width: '100%'}}
            value={folder.eans}
            tips="Un EAN par ligne, ou séparés par des virgules"
            rows="20"
          />

          <div className="folder-form_row">
            <Select
              errors={errors.status}
              label="Statut"
              name="status"
              options={[
                {
                  label: 'En préparation',
                  value: 'draft',
                },
                {
                  label: 'Publié',
                  value: 'published',
                },
              ]}
              onChange={handleChange}
              value={folder.status}
            />
          </div>

          <Checkbox
            errors={errors.includedInNewsletter}
            label="Newsletter"
            name="includedInNewsletter"
            onChange={handleChange}
            style={{width: '100%', marginRight: '10px'}}
            value={folder.includedInNewsletter}
          />

        </div>

        <div className="folder-form_column">
          <Input
            errors={errors.title}
            label="Titre"
            name="title"
            onChange={handleChange}
            type="text"
            value={folder.title}
          />

          <SummaryEditor
            errors={errors.summary}
            label="Introduction"
            name="summary"
            onChange={handleChange}
            value={folder.summary}
          />

          <ArticleContentEditor
            errors={errors.content}
            label="Contenu"
            name="content"
            onChange={handleChange}
            value={folder.content}
          />
        </div>

        <Button
          className="folder-form_submit-button"
          label={`${props.id ? 'Modifier' : 'Créer'} le dossier`}
          theme="success"
          type="submit"
        />
      </Form>
    </div>
  );
};

FolderForm.propTypes = {
  id: PropTypes.number,
};

export default FolderForm;
