import axios from '../api/config';

const LOGIN_URL = `${process.env.REACT_APP_API_BASE_URL}/login`;
const LOGOUT_URL = `${process.env.REACT_APP_LOGOUT_URL}`;
const ME_URL = `${process.env.REACT_APP_API_BASE_URL}/me`;

const login = (form, successCb, errorCb) => {
  axios.post(
    LOGIN_URL,
    form,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const logout = (successCb, errorCb) => {
  axios.get(
    LOGOUT_URL,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const me = (successCb, errorCb) => {
  axios.get(
    ME_URL,
  ).then(
    res => successCb(res.data),
  ).catch(
    err => errorCb(err),
  );
};

const endpoints = {
  login,
  logout,
  me,
};

export default endpoints;
