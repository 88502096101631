import React from 'react';
import {
  useParams,
} from 'react-router-dom';
import HighlightedLinkForm from '../components/highlighted-link-form';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const HighlightedLinkPage = () => {
  const { id } = useParams();

  return (
    <Layout>
      <div className="page">
        <PageHeader
          title={`${id ? 'Modifier' : 'Créer'} un lien`}
        />

        <div className="page_actions">
          <LinkButton
            className="page_action-button"
            icon="arrow_back"
            label="Retour"
            to="/en-ce-moment"
            theme="danger"
          />
        </div>

        <HighlightedLinkForm id={parseInt(id, 10)} />
      </div>
    </Layout>
  );
};

export default HighlightedLinkPage;
