import React from 'react';
import FolderList from '../components/folder-list';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const FoldersPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Dossiers"
        description="Créer, éditer ou supprimer des dossiers."
      />

      <div className="page_actions">
        <LinkButton
          className="page_action-button"
          icon="add"
          label="Créer un dossier"
          to="/dossiers/creer"
          theme="success"
        />
      </div>

      <FolderList />
    </div>
  </Layout >
);

export default FoldersPage;
