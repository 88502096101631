import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import './page.css';

const ParametersPage = () => (
  <Layout>
    <div className="page">
      <PageHeader
        title="Paramètres"
        description="Gestion des paramètres."
      />
      <p>Work in progress...</p>
    </div>
  </Layout >
);

export default ParametersPage;
