import React from 'react';
import LibraryBookCreate from '../components/library-book-create';
import Layout from '../components/layout';
import LinkButton from '../components/link-button';
import PageHeader from '../components/page-header';
import './page.css';

const LibraryBookCreatePage = () => {
  return (
    <Layout>
      <div className="page">
        <PageHeader
          title="Importer des livres"
        />

        <div className="page_actions">
          <LinkButton
            className="page_action-button"
            icon="arrow_back"
            label="Retour"
            to="/librairie/livres"
            theme="danger"
          />
        </div>

        <LibraryBookCreate />
      </div>
    </Layout>
  );
};

export default LibraryBookCreatePage;
