import React from 'react';
import { Link } from 'react-router-dom';
import LayoutNav from '../layout-nav';
import { useAuth } from '../../context/auth-context';
import { useUser } from '../../context/user-context';
import { useUI } from '../../context/ui-context';
import './layout.css';

const Layout = props => {
  const [isNavActive, setIsNavActive] = useUI();
  const user = useUser();
  const { logout } = useAuth();

  return (
    <div className="layout">
      <div className="layout_header">
        <button
          className="layout_nav-button"
          type="button"
          onClick={() => setIsNavActive(!isNavActive)}
        >
          <i className="layout_nav-icon material-icons">menu</i>
        </button>
        <Link
          className="layout_logo"
          to="/"
        >
          <span style={{ color: '#333' }}>Actua</span>Litté
        </Link>

        <div className="layout_user">
          <p className="layout_user-text">Bienvenue, {user.username} !</p>

          <button
            className="layout_logout-button"
            onClick={logout}
            title="Déconnexion"
            type="button"
          >
            <i className="layout_logout-button-icon material-icons">power_settings_new</i>
          </button>
        </div>
      </div>

      <div className="layout_body">
        <div className={`layout_sidebar${isNavActive ? ' layout_sidebar--active' : ''}`}>
          <LayoutNav className="layout_sidebar" />
        </div>
        <div className="layout_content">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
