import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import useQuery from '../../hooks/use-query';
import Loader from '../loader';
import Paginator from '../paginator';
import api from '../../api';
import './newsletter-list.css';

const statusesLabels = {
  draft: 'En préparation',
  prepared: 'Prêt',
  sent: 'Envoyé',
  error: 'Erreur',
};

const NewsletterStatus = ({ status }) => (
  <span className={`newsletter-list_status-badge newsletter-list_status-badge--${status}`}>
    {statusesLabels[status]}
  </span>
);

let searchUpdateTimeout = null;

const NewsletterList = () => {
  const query = useQuery();
  const page = query.get('page') ? query.get('page') : 1;
  const itemsPerPage = query.get('itemsPerPage') ? query.get('itemsPerPage') : 25;
  const search = query.get('search') ? query.get('search') : '';
  const status = query.get('status') ? query.get('status') : '';
  const sortBy = query.get('sortBy') ? query.get('sortBy') : 'sendDate';
  const sortDir = query.get('sortDir') ? query.get('sortDir') : 'DESC';
  const generateRouteParams = useCallback(params => {
    let routeParams = [];

    if (params.search || (search && !params.hasOwnProperty('search'))) {
      routeParams.push(`search=${params.hasOwnProperty('search') ? params.search : search}`);
    }

    if (params.status || (status && !params.hasOwnProperty('status'))) {
      routeParams.push(`status=${params.hasOwnProperty('status') ? params.status : status}`);
    }

    if (params.sortBy || (sortBy && !params.hasOwnProperty('sortBy'))) {
      routeParams.push(`sortBy=${params.hasOwnProperty('sortBy') ? params.sortBy : sortBy}`);
    }

    if (params.sortDir || (sortDir && !params.hasOwnProperty('sortDir'))) {
      routeParams.push(`sortDir=${params.hasOwnProperty('sortDir') ? params.sortDir : sortDir}`);
    }

    if (params.itemsPerPage || (itemsPerPage && !params.hasOwnProperty('itemsPerPage'))) {
      routeParams.push(`itemsPerPage=${params.hasOwnProperty('itemsPerPage') ? params.itemsPerPage : itemsPerPage}`);
    }

    if (params.page || (page && !params.hasOwnProperty('page'))) {
      routeParams.push(`page=${params.hasOwnProperty('page') ? params.page : page}`);
    }

    return `/newsletters?${routeParams.join('&')}`;
  }, [status, search, sortBy, sortDir, itemsPerPage, page]);
  const [redirect, setRedirect] = useState(null);
  const [newsletters, setNewsletters] = useState([]);
  const [pages, setPages] = useState(0);
  const [nbResults, setNbResults] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const changeSort = by => {
    let dir = by === sortBy ? sortDir === 'DESC' ? 'ASC' : 'DESC' : 'ASC';

    setRedirect(generateRouteParams({sortBy: by, sortDir: dir, page: 1}));
  };

  useEffect(() => {
    setRedirect(null);
    setIsLoading(true);
    api.newsletters.list(
      {
        search: search,
        page: page,
        items_per_page: itemsPerPage,
        sort_by: sortBy,
        sort_dir: sortDir,
        filters: {
          status,
        },
      },
      data => {
        setNewsletters(data.items);
        setPages(data.pageCount);
        setNbResults(data.totalCount);
        setError(null);
        setIsLoading(false);
      },
      err => {
        setError(err);
        setIsLoading(false);
      },
    );
  }, [search, itemsPerPage, page, sortBy, sortDir, status]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="newsletter-list">
      <div className="newsletter-list_nav">
        <div className="newsletter-list_search">
          <input
            className="newsletter-list_search-input"
            type="text"
            onChange={e => {
              let val = e.target.value;
              setSearchInput(val);
              clearTimeout(searchUpdateTimeout);
              searchUpdateTimeout = setTimeout(() => {
                setRedirect(generateRouteParams({search: val, page: 1}));
              }, 600);
            }}
            placeholder="Rechercher des newsletters"
            value={searchInput}
          />
        </div>

        <div className="newsletter-list_pagination">
          <span className="newsletter-list_count">Newsletters {(page - 1) * itemsPerPage + 1} à {(page - 1) * itemsPerPage + Number(itemsPerPage, 10)} sur {nbResults} résultats</span>
          <select
            className="newsletter-list_items-per-page"
            id="ipp"
            onChange={e => setRedirect(generateRouteParams({itemsPerPage: e.target.value, page: 1}))}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      {isLoading && <Loader />}

      {!isLoading && error && <p className="newsletter-list_error">Une erreur s'est produite, essayez de réactualiser la page.</p>}

      {!isLoading && !error && (
        <div>
          <div className="newsletter-list_table">
            <div className="newsletter-list_header">
              <div
                className="newsletter-list_header-cell newsletter-list_header-cell--sortable newsletter-list_cell--id"
                onClick={() => changeSort('id')}
              >
                ID
                {sortBy === 'id' && (
                  <i className="newsletter-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="newsletter-list_header-cell newsletter-list_header-cell--sortable newsletter-list_cell--date"
                onClick={() => changeSort('sendDate')}
              >
                Date d'envoi
                {sortBy === 'sendDate' && (
                  <i className="newsletter-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div
                className="newsletter-list_header-cell newsletter-list_header-cell--sortable newsletter-list_cell--title"
                onClick={() => changeSort('slug')}
              >
                Titre
                {sortBy === 'slug' && (
                  <i className="newsletter-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>

              <div className="newsletter-list_header-cell newsletter-list_cell--status">
                Statut
              </div>

              <div
                className="newsletter-list_header-cell newsletter-list_header-cell--sortable newsletter-list_cell--views"
                onClick={() => changeSort('nbView')}
              >
                Vues
                {sortBy === 'nbView' && (
                  <i className="newsletter-list_sort-icon material-icons">{sortDir === 'ASC' ? 'expand_less' : 'expand_more'}</i>
                )}
              </div>
            </div>

            {newsletters.length > 0 && (
              <div>
                {newsletters.map(newsletter => (
                  <div
                    className="newsletter-list_row"
                    key={newsletter.id}
                  >
                    <Link
                      className="newsletter-list_cell newsletter-list_cell--id"
                      title="Éditer la newsletter"
                      to={`/newsletters/${newsletter.id}/modifier`}
                    >
                      {newsletter.id}
                    </Link>

                    <Link
                      className="newsletter-list_cell newsletter-list_cell--date"
                      title="Éditer la newsletter"
                      to={`/newsletters/${newsletter.id}/modifier`}
                    >
                      {moment(newsletter.sendDate).format('DD/MM/YYYY')}
                    </Link>

                    <Link
                      className="newsletter-list_cell newsletter-list_cell--title"
                      title="Éditer la newsletter"
                      to={`/newsletters/${newsletter.id}/modifier`}
                    >
                      {newsletter.title}
                    </Link>


                    <div className="newsletter-list_cell newsletter-list_cell--status">
                      <NewsletterStatus status={newsletter.status} />
                    </div>

                    <Link
                      className="newsletter-list_cell newsletter-list_cell--views"
                      title="Nombre de vues"
                      to={`/newsletters/${newsletter.id}/modifier`}
                    >
                      {newsletter.nbView}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>

          <Paginator
            currentPage={page}
            maxPage={pages}
            routePattern={generateRouteParams({page: '[page]'})}
          />
      </div>
      )}

      {newsletters.length === 0 && !isLoading && (
        <p className="newsletter-list_no-result">Aucun résultat.</p>
      )}
    </div>
  );
};

export default NewsletterList;
